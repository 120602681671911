import { returnArrayIfIsset } from "utils";
import { useReferenceGetAllProcessState } from "../endpoints/references";

/** Kompleksning jarayon ro'yhatini olish  */
export const useProcessStateOptions = () => {
  const { data } = useReferenceGetAllProcessState();

  const processStateOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    processStateOptions,
  };
};
