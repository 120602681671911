import { QueryOptions } from "hooks/custom";
import { CustomerDto, ErrorDto, Pageable, PageableResp2 } from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";

export const useReceiptsGetCustomers = (
  params: { pinfl?: string } & Pageable,
  options?: QueryOptions<PageableResp2<CustomerDto>, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/receipts/get-customers",
    options,
    params,
  });
