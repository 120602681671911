import { MutationOptions } from "hooks/custom";
import { DeleteDto, ErrorDto, ReferenceModifyDto } from "types";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";

export const usePrReferenceVillageModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/village-modify",
    method: "POST",
    options,
  });

export const usePrReferenceRentTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/rent-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceRelativeTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/relative-type-modify",
    method: "POST",
    options,
  });

export const usePrReferencePopulationTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/population-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceNoLiveTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/no-live-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceEmploymentTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/employment-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceCurrentTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/current-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceBasisTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/basis-type-modify",
    method: "POST",
    options,
  });

export const usePrReferenceAnimalModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/animal-modify",
    method: "POST",
    options,
  });

// ====================================================  DELETE   ====================================================
export const usePrReferenceVillageDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/village-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceRentTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/rent-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceRelativeTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/relative-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferencePopulationTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/population-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceNoLiveTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/no-live-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceEmploymentTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/employment-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceCurrentTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/current-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceBasisTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/basis-type-delete",
    method: "DELETE",
    options,
  });

export const usePrReferenceAnimalDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-reference/animal-delete",
    method: "DELETE",
    options,
  });
