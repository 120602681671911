import { MutationOptions } from "hooks/custom";
import { EntranceCopyDto, ErrorReason } from "types";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";

export const useEntrancesCopy = (
  options?: MutationOptions<unknown, ErrorReason, EntranceCopyDto>
) =>
  useGeneralAPIMutation({
    url: "/entrances/copy",
    method: "POST",
    options,
  });
