import { QueryOptions } from "hooks/custom";
import { ErrorReason, PageableParam, ResponsePagingDTOOrderDto } from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";

export const useOrdersGetAll = (
  params: PageableParam<{ flatId: number }>,
  options?: QueryOptions<ResponsePagingDTOOrderDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/orders/get-all",
    options,
    params,
  });
