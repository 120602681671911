import {
  AppFileDto,
  ComplexCustomsDto,
  ComplexGovernmentDto,
  ErrorReason,
  NameDto,
  Pageable,
  PageableParam,
  PageableResp,
  ResponsePagingDTOComplexArchitectDto,
  ResponsePagingDTOComplexGovernmentDto,
  ShortDto,
} from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";
import { QueryOptions } from "hooks";

export const useComplexesGetAll = (
  params?: PageableParam<{
    companyId?: number;
    name?: string;
    classId?: number;
    materialId?: number;
    stateId?: number;
  }>,
  options?: QueryOptions<PageableResp<ComplexCustomsDto>, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/complexes/get-all",
    options,
    params,
  });

export const useComplexesGetDetails = (
  id: number,
  options?: QueryOptions<AppFileDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/complexes/get-details/${id}`,
    options,
  });

export const useComplexesGetDocuments = (
  id: number,
  options?: QueryOptions<AppFileDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/complexes/get-documents/${id}`,
    options,
  });

export const useComplexesGetPhotos = (
  id: number,
  options?: QueryOptions<AppFileDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/complexes/get-photos/${id}`,
    options,
  });

export const useComplexesGet = (
  id: number,
  options?: QueryOptions<ComplexCustomsDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/complexes/get/${id}`,
    options,
  });

export const useComplexesGetAllShort = (
  params?: { stateId?: number },
  options?: QueryOptions<ShortDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/complexes/get-all-short`,
    params,
    options,
  });

export interface ComplexesArchitectParams extends Pageable {
  companyId?: number;
  name?: string;
  classId?: number;
  materialId?: number;
  stateId: number;
}

export const useComplexesGetAllForArchitect = (
  params: ComplexesArchitectParams,
  options?: QueryOptions<ResponsePagingDTOComplexArchitectDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/complexes/get-all-for-architect",
    params,
    options,
  });

export interface ComplexesGovernmentParams extends Pageable {
  companyId?: number | string;
  processStateId?: number | string;
  stateId?: number | string;
  complexId?: number | string;
}

export const useComplexesGetAllForGovernment = (
  params?: ComplexesGovernmentParams,
  options?: QueryOptions<ResponsePagingDTOComplexGovernmentDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/complexes/get-all-for-government",
    params,
    options,
  });

export const useComplexesGovernmentStatus = (
  options?: QueryOptions<NameDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/complexes/government-status",
    options,
  });

export const useComplexesGetAllForAddBlock = (
  options?: QueryOptions<ShortDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/complexes/get-all-for-add-block",
    options,
  });
