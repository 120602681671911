import { useReferenceGetAllCompanyType } from "../endpoints/references";

/** Korxona turlarni ro'yxatni olish */
export const useCompanyTypeOptions = () => {
  const { data } = useReferenceGetAllCompanyType();

  const companyTypeOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    companyTypeOptions,
  };
};
