import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { DeleteDto, ErrorDto, ReferenceModifyDto, ReferenceParentModifyDto } from "types";

/** @description Xona turi qo'shish yoki o'zgartirish */
export const useReferencesSpRoomTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-room-type-modify",
    method: "POST",
    options,
  });

/** @description Obyekt turi qo'shish yoki o'zgartirish */
export const useReferencesSpObjectTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-object-type-modify",
    method: "POST",
    options,
  });

/** @description Xonadon turi qo'shish yoki o'zgartirish */
export const useReferencesSpFlatTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-flat-type-modify",
    method: "POST",
    options,
  });

/** @description Xonadon xolati qo'shish yoki o'zgartirish */
export const useReferencesSpFlatStateModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-flat-state-modify",
    method: "POST",
    options,
  });

/** @description Qulayliklar ro'yhatini qo'shish yoki o'zgartirish */
export const useReferencesSpDetailModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-detail-modify",
    method: "POST",
    options,
  });

/** @description Majmua holatini qo'shish yoki o'zgartirish */
export const useReferencesSpComplexStateModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-state-modify",
    method: "POST",
    options,
  });

/** @description Complex narx qo'shish yoki o'zgartirish */
export const useReferencesSpComplexPriceModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-price-modify",
    method: "POST",
    options,
  });

/** @description Majmua material turi qo'shish yoki o'zgartirish */
export const useReferencesSpComplexMaterialTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-material-type-modify",
    method: "POST",
    options,
  });

/** @description Majmua sinfini qo'shish yoki o'zgartirish */
export const useReferencesSpComplexClassModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-class-modify",
    method: "POST",
    options,
  });

/** @description Korxona turini qo'shish yoki o'zgartirish */
export const useReferencesSpCompanyModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-company-modify",
    method: "POST",
    options,
  });

/** @description Viloyat qo'shish yoki o'zgartirish */
export const useReferenceRegionModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/region-modify",
    method: "POST",
    options,
  });

/** @description Jarayon holati qo'shish yoki o'zgartirish */
export const useReferenceProcessStateModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/process-state-modify",
    method: "POST",
    options,
  });

/** @description Tuman qo'shish yoki o'zgartirish */
export const useReferenceDistrictModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceParentModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/district-modify",
    method: "POST",
    options,
  });

/** @description Valyuta qo'shish yoki o'zgartirish */
export const useReferenceCurrencyModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/currency-modify",
    method: "POST",
    options,
  });

/** @description Mahalla qo'shish yoki o'zgartirish */
export const useReferenceAreaModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceParentModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/area-modify",
    method: "POST",
    options,
  });

/** @description Schema turini qo'shish yoki o'zgartirish */
export const useReferenceResidentSchemaTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/resident-schema-type-modify",
    method: "POST",
    options,
  });

/** @description Schema turini qo'shish yoki o'zgartirish */
export const useReferenceNoResidentSchemaTypeModify = (
  options?: MutationOptions<unknown, ErrorDto, ReferenceModifyDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/no-resident-schema-type-modify",
    method: "POST",
    options,
  });

// ====================================================  DELETE   ====================================================
/** @description Xona turi o'chirish */
export const useReferenceModifySpRoomTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-room-type-delete",
    method: "DELETE",
    options,
  });

/** @description Obyekt turi o'chirish */
export const useReferenceModifySpObjectTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-object-type-delete",
    method: "DELETE",
    options,
  });

/** @description Xonadon turi o'chirish */
export const useReferenceModifySpFlatTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-flat-type-delete",
    method: "DELETE",
    options,
  });

/** @description Xonadon xolati o'chirish */
export const useReferenceModifySpFlatStateDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-flat-state-delete",
    method: "DELETE",
    options,
  });

/** @description Majmua holatini o'chirish */
export const useReferenceModifySpDetailDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-detail-delete",
    method: "DELETE",
    options,
  });

/** @description Majmua holatini o'chirish */
export const useReferenceModifySpComplexStateDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-state-delete",
    method: "DELETE",
    options,
  });

/** @description Complex narx o'chirish */
export const useReferenceModifySpComplexPriceDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-price-delete",
    method: "DELETE",
    options,
  });

/** @description Majmua material turi o'chirish */
export const useReferenceModifySpComplexMaterialTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-material-type-delete",
    method: "DELETE",
    options,
  });

/** @description Majmua sinfini o'chirish */
export const useReferenceModifySpComplexClassDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-complex-class-delete",
    method: "DELETE",
    options,
  });

/** @description Korxona turini o'chirish */
export const useReferenceModifySpCompanyDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/sp-company-delete",
    method: "DELETE",
    options,
  });

/** @description Viloyat o'chirish */
export const useReferenceModifyRegionDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/region-delete",
    method: "DELETE",
    options,
  });

/** @description Jarayon holati o'chirish */
export const useReferenceModifyProcessStateDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/process-state-delete",
    method: "DELETE",
    options,
  });

/** @description Tuman o'chirish */
export const useReferenceModifyDistrictDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/district-delete",
    method: "DELETE",
    options,
  });

/** @description Valyuta o'chirish */
export const useReferenceModifyCurrencyDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/currency-delete",
    method: "DELETE",
    options,
  });

/** @description Mahalla o'chirish */
export const useReferenceModifyAreaDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/area-delete",
    method: "DELETE",
    options,
  });

/** @description Schema turini o'chirish */
export const useReferenceModifySchemaTypeDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/reference-modify/schema-type-delete",
    method: "DELETE",
    options,
  });
