import { FC, useContext, useEffect, useMemo } from "react";
import { useComplexesGet, useFile, useFilesManager } from "hooks";
import { useParams } from "react-router-dom";
import { Image, Skeleton } from "antd";
import { DetailInfoContext } from "../DetailInfoContext";
import classes from "../DetailInfo.module.scss";
import { FALLBACK_EMPTY_IMAGE } from "constants/other";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

export const Slider: FC = () => {
  const { clientHeight } = useContext(DetailInfoContext);
  const { complexId } = useParams<{ complexId: string }>();
  const { guidsToFiles, isLoadingGuidsToFiles } = useFilesManager();
  const { setFiles, files } = useFile();

  const complexQuery = useComplexesGet(Number(complexId), { enabled: !!complexId });
  const dataComplex = useMemo(() => complexQuery?.data?.data, [complexQuery.data]);

  useEffect(() => {
    (async () => {
      const photoFiles = await guidsToFiles(dataComplex?.photoGuids ?? []);
      setFiles(photoFiles);
    })();
  }, [dataComplex, setFiles]);

  const pagination: SwiperProps["pagination"] = {
    type: "fraction",
    renderFraction(currentClass: string, totalClass: string) {
      return `<div class="swiper-pagination-inner">
        <span class="${currentClass}"></span>/<span class="${totalClass}"></span>
      </div>`;
    },
  };

  if (complexQuery.isLoading || isLoadingGuidsToFiles) {
    return (
      <Skeleton.Image
        className="w100"
        rootClassName="w100"
        style={{ height: clientHeight }}
        active
      />
    );
  }

  if (!files.length) {
    return (
      <Image
        src="error"
        className="w100"
        rootClassName="w100"
        height={clientHeight}
        style={{ objectFit: "cover" }}
        fallback={FALLBACK_EMPTY_IMAGE}
      />
    );
  }

  return (
    <Swiper
      effect="fade"
      loop={true}
      pagination={pagination}
      autoplay={{
        delay: 3000,
      }}
      modules={[EffectFade, Pagination, Autoplay]}
      className={classes.slider}
    >
      {files.map((file, idx) => {
        return (
          <SwiperSlide key={idx} className={classes.slideItem}>
            <div className={classes.slideContent} style={{ height: clientHeight }}>
              <Image.PreviewGroup items={files.map((file) => URL.createObjectURL(file))}>
                <Image
                  rootClassName="w100"
                  height={clientHeight}
                  className={classes.slideImage}
                  alt={file.name ?? "slide-image"}
                  src={URL.createObjectURL(file)}
                />
              </Image.PreviewGroup>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
