import dayjs from "dayjs";
import {
  DATE_FORMAT2 as PARSE_FOR_DATEPICKER_DATE_FORMAT,
  DATE_FORMAT as UI_DATE_FORMAT,
} from "constants/general";

export const formatDateToDisplay = (date: dayjs.ConfigType) => {
  if (!date) return date;

  return dayjs(date).format(UI_DATE_FORMAT);
};

export const parseToDate = (date: dayjs.ConfigType): any => {
  if (!date) return undefined;

  return dayjs(date, PARSE_FOR_DATEPICKER_DATE_FORMAT);
};

export const formatDateToSend = (date: dayjs.ConfigType) => {
  if (!date) return date;

  return dayjs(date).format(PARSE_FOR_DATEPICKER_DATE_FORMAT);
};
