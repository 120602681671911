import { useReferenceGetAllComplexState } from "../endpoints/references";
import { get } from "lodash";

/** Kompleks Statuslar ro'yxatni olish */
export const useComplexStateOptions = () => {
  const { data } = useReferenceGetAllComplexState();

  const complexStateOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, ...item }) => ({
        value: id,
        label: get(item, "name", "-"),
      }))
    : [];

  return {
    complexStateOptions,
  };
};
