import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react";

export interface DetailInfoContextProps {
  clientHeight: number;
  setClientHeight: Dispatch<SetStateAction<number>>;
}

const DEFAULT_CLIENT_HEIGHT = 377;

export const DetailInfoContext = createContext<DetailInfoContextProps>(
  {} as DetailInfoContextProps
);

export const DetailInfoContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [clientHeight, setClientHeight] = useState(DEFAULT_CLIENT_HEIGHT);

  return (
    <DetailInfoContext.Provider
      value={{
        clientHeight: clientHeight ?? DEFAULT_CLIENT_HEIGHT,
        setClientHeight,
      }}
    >
      {children}
    </DetailInfoContext.Provider>
  );
};
