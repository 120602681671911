import { returnArrayIfIsset } from "utils";
import { useComplexesGetAllShort } from "../endpoints/complexes";

/** Barcha Kompleks nomi va idsini olish */
export const useComplexesShortOptions = () => {
  const getAllQuery = useComplexesGetAllShort();

  const getQuery = useComplexesGetAllShort({ stateId: 1 });

  const complexesShortAllOptions = returnArrayIfIsset(getAllQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  const complexesShortOptions = returnArrayIfIsset(getQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  return {
    complexesShortAllOptions,
    complexesShortOptions,
  };
};
