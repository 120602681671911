import { lazy, useMemo } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { NotFound } from "components/shared";
import { useUser } from "hooks";
import { UserRoles } from "types";

const Builder = lazy(() => import("pages/Builder"));
const Architect = lazy(() => import("pages/Architect"));
const Government = lazy(() => import("pages/Government"));
const Privacy = lazy(() => import("pages/Privacy"));
const Admin = lazy(() => import("pages/Admin"));
const Auth = lazy(() => import("pages/Auth"));
const Xatlov = lazy(() => import("pages/Xatlov"));

export default function AllRoutes() {
  const { isLogged, role } = useUser();

  const notFoundRoutes = [
    { path: "*", element: <Navigate to="/404" /> },
    { path: "/404", element: <NotFound /> },
  ];

  const notAuthRoutes = [
    { path: "*", element: <Navigate to="/auth/sign-in" /> },
    { path: "/auth/*", element: <Auth /> },
  ];

  const publicRoutes = useRoutes([
    {
      path: "/privacy",
      element: <Privacy />,
    },
    ...notAuthRoutes,
  ]);

  const privateRoutes: Partial<Record<UserRoles, RouteObject[]>> = useMemo(
    () => ({
      BUILDER: [{ path: "/*", element: <Builder />, index: false }],
      ARCHITECTURE: [{ path: "/*", element: <Architect />, index: false }],
      GOVERNMENT: [{ path: "/*", element: <Government />, index: false }],
      ADMIN: [{ path: "/*", element: <Admin />, index: false }],
      XATLOV: [{ path: "/*", element: <Xatlov />, index: false }],
    }),
    []
  );

  const allPrivateRoutes = useRoutes([
    ...(privateRoutes?.[role!] ?? []),
    ...notFoundRoutes,
  ]);

  return isLogged ? allPrivateRoutes : publicRoutes;
}
