import { useReferenceGetAllComplexMaterialType } from "../endpoints/references";

/** Kompleks klass materiallar ro'yxatni olish */
export const useComplexMaterialTypeOptions = () => {
  const { data } = useReferenceGetAllComplexMaterialType();

  const complexMaterialTypeOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, ...item }) => ({
        value: id,
        label: item?.name,
      }))
    : [];

  return {
    complexMaterialTypeOptions,
  };
};
