import { isArray } from "utils";
import { useReferenceGetAllRoomType } from "../endpoints/references";

/** Xonadon turlari ro'yxatni olish */
export const useRoomTypeOptions = () => {
  const { data } = useReferenceGetAllRoomType();

  const roomTypeOptions = isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    roomTypeOptions,
  };
};
