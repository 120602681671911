import { ComponentProps, FC, Suspense } from "react";
import classes from "./YandexMap.module.scss";
import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
  YMaps,
} from "react-yandex-map";

export const INITIAL_COORDINATES = [41.11905945245338, 69.05927678308097];

export interface YandexMapProps extends ComponentProps<typeof Map> {
  placemark?: ComponentProps<typeof Placemark>;
}

export const YandexMap: FC<YandexMapProps> = ({ placemark, ...props }) => {
  return (
    <Suspense>
      <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_API_KEY }}>
        <Map
          className={classes.map}
          width="100%"
          height="100%"
          defaultState={{
            center: INITIAL_COORDINATES,
            zoom: 9,
            controls: [],
          }}
          {...props}
        >
          {placemark && <Placemark defaultGeometry={INITIAL_COORDINATES} {...placemark} />}
          <FullscreenControl />
          <SearchControl />
          <TypeSelector />
        </Map>
      </YMaps>
    </Suspense>
  );
};

// const onHandleClick = (e: any) => {
//   const cords = get(e, "_sourceEvent.originalEvent.coords", []);
//   console.log("objectLatitude", cords?.[0]);
//   console.log("objectLongitude", cords?.[1]);
// };
