import { QueryOptions, useGeneralAPIQuery } from "hooks";
import { ErrorDto, Pageable, ResponsePagingDTOCompanyDto } from "types";

export const useCompaniesGetAll = (
  params?: { tin?: string } & Pageable,
  options?: QueryOptions<ResponsePagingDTOCompanyDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/companies/get-all`,
    options,
    params,
  });

export const useCompaniesGetAllShort = (
  params?: { type?: string },
  options?: QueryOptions<Record<string, object>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/companies/get-all-short`,
    params,
    options,
  });
