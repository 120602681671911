import { FC, PropsWithChildren } from "react";
import { Tag } from "antd";
import { COMPLEX_PROCESS_STATUS } from "constants/ui";

export const ComplexProcessStatus: FC<PropsWithChildren<{ id: number }>> = ({
  id,
  children,
}) => {
  return (
    <Tag
      color={(() => {
        switch (id) {
          case COMPLEX_PROCESS_STATUS.IN_PROGRESS:
            return "processing";
          case COMPLEX_PROCESS_STATUS.PLANNED:
            return "warning";
          case COMPLEX_PROCESS_STATUS.COMPLETED:
            return "success";
          default:
            return "processing";
        }
      })()}
    >
      {children}
    </Tag>
  );
};
