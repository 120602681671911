import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllRelativeTypes } from "../endpoints/pr-reference";

export const useRelativeTypesOptions = () => {
  const { data } = usePrReferenceGetAllRelativeTypes();

  const relativeTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    relativeTypesOptions,
  };
};
