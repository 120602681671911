import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { BlockCreateDto, BlockUpdateDto, DeleteDto, ErrorReason } from "types";

export const useBlocksCreate = (
  options?: MutationOptions<unknown, ErrorReason, BlockCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/blocks/create",
    method: "POST",
    options,
  });

export const useBlocksDelete = (
  options?: MutationOptions<unknown, ErrorReason, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/blocks/delete",
    method: "DELETE",
    options,
  });

export const useBlocksUpdate = (
  options?: MutationOptions<unknown, ErrorReason, BlockUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/blocks/update",
    method: "POST",
    options,
  });

export const useBlocksChangeEndDate = (
  options?: MutationOptions<unknown, ErrorReason, { id: number; endDate: string }>
) =>
  useGeneralAPIMutation({
    url: "/blocks/change-end-date",
    method: "PUT",
    options,
  });
