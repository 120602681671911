import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllEmploymentTypes } from "../endpoints/pr-reference";

export const useEmploymentTypesOptions = () => {
  const { data } = usePrReferenceGetAllEmploymentTypes();

  const employmentTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    employmentTypesOptions,
  };
};
