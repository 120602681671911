import { returnArrayIfIsset } from "utils";
import { useUsersRoles } from "../endpoints/users";

/** Rolelar ro'yhati */
export const useUserRolesOptions = () => {
  const { data } = useUsersRoles();

  const userRolesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    userRolesOptions,
  };
};
