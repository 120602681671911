import { MutationOptions } from "hooks/custom";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { DeleteDto, ErrorDto, PrApartmentCreateDto, PrApartmentUpdateDto } from "types";

export const usePrApartmentCreate = (
  options?: MutationOptions<unknown, ErrorDto, PrApartmentCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-apartment/create",
    method: "POST",
    options,
  });

export const usePrApartmentUpdate = (
  options?: MutationOptions<unknown, ErrorDto, PrApartmentUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-apartment/update",
    method: "PUT",
    options,
  });

export const usePrApartmentDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-apartment/delete",
    method: "DELETE",
    options,
  });

export const usePrApartmentGet = (
  id: number | string,
  options?: MutationOptions<PrApartmentUpdateDto, ErrorDto>
) =>
  useGeneralAPIMutation({
    url: `/pr-apartment/get/${id}`,
    method: "POST",
    options,
  });
