import PageMenu from "./PageMenu";
import PageTitle from "./PageTitle";
import PageTitleWithBack from "./PageTitleWithBack";

export const Page = () => {
  return <></>;
};

Page.Menu = PageMenu;
Page.Title = PageTitle;
Page.TitleWithBack = PageTitleWithBack;
