import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { Icon } from "components/shared";

export interface MobilePublishedProps {
  isVisible: boolean;
}

export const MobilePublished: FC<MobilePublishedProps> = ({ isVisible }) => {
  const { t } = useTranslation();

  return isVisible ? (
    <Tooltip title={t("Мобиль иловага нашр қилинди")}>
      <Icon name="phone-print" width={25} />
    </Tooltip>
  ) : (
    <Tooltip title={t("Мобиль иловага нашр қилинмаган")}>
      <Icon name="phone-unprint" width={25} />
    </Tooltip>
  );
};
