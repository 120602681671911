import { returnArrayIfIsset } from "utils";
import { useCompaniesGetAllShort } from "../endpoints/companies";
import { QueryOptions } from "hooks";
import { ErrorDto } from "types";

/** Barcha korxonalarni olish */
export const useCompaniesShortOptions = (
  type?: string,
  options?: QueryOptions<Record<string, object>[], ErrorDto>
) => {
  const { data } = useCompaniesGetAllShort({ type }, options);

  const companiesShortOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id as unknown as string,
    label: name as unknown as string,
  }));

  return {
    companiesShortOptions,
  };
};
