import { notification } from "antd";
import { useChunkFilesUpload } from "hooks/mutations";
import { useTranslation } from "react-i18next";

export type UploadChunkFn = (
  chunkIndex: number,
  response?: { fileGuid: string | undefined; uploadDate: string | undefined }
) => void;

const CHUNK_SIZE = 1024 * 1000; // 1024 kilobytes

export const useChunkUpload = () => {
  const { t } = useTranslation();
  const chunkFilesUploadMutation = useChunkFilesUpload();

  async function chunkUploadFile(
    file: File,
    resolve: (data: string | undefined) => void
  ): Promise<void> {
    const chunkSize = CHUNK_SIZE;
    const totalChunks = Math.ceil(file.size / chunkSize);
    let uploadedChunks = 0;
    let uploadSize = 0;

    const uploadChunk: UploadChunkFn = async (
      chunkIndex: number,
      { fileGuid, uploadDate } = { fileGuid: undefined, uploadDate: undefined }
    ) => {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);

      const chunkData = file.slice(start, end);

      uploadSize += chunkData.size;

      const formData = new FormData();
      formData.append("fileName", file.name);
      formData.append("contentType", file.type);
      formData.append("extension", (file.name ?? "")?.split(".").pop()!);
      formData.append("chunkNumber", String(uploadedChunks + 1));
      formData.append("totalChunks", String(totalChunks));
      formData.append("chunkData", chunkData);

      if (fileGuid && uploadDate) {
        formData.append("fileGuid", fileGuid);
        formData.append("uploadDate", uploadDate);
      }

      await chunkFilesUploadMutation
        .mutateAsync({ payload: formData })
        .then(async ({ data }) => {
          uploadedChunks++;

          if (uploadedChunks === totalChunks) {
            resolve(data?.fileGuid);
          } else {
            await uploadChunk(uploadedChunks, {
              fileGuid: data?.fileGuid,
              uploadDate: data?.uploadDate,
            });
          }
        })
        .catch(({ message, response }) => {
          notification.error({
            message: t("Файлларни юклашда хатолик юз берди"),
            description: response?.data?.message ?? message,
          });
        });
    };

    await uploadChunk(0);
  }

  return {
    chunkUploadFile,
  };
};
