import { MutationOptions, useGeneralAPIMutation } from "hooks";
import {
  ComplexChangeStateDto,
  ComplexChangesStateDto,
  ComplexCreateDto,
  ComplexUpdateDto,
  DeleteDto,
  ErrorReason,
  RespData,
} from "types";

export const useComplexesUpdate = (
  options?: MutationOptions<RespData<unknown>, ErrorReason, ComplexUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/update",
    method: "POST",
    options,
  });

export const useComplexesCreate = (
  options?: MutationOptions<RespData<unknown>, ErrorReason, ComplexCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/create",
    method: "POST",
    options,
  });

export const useComplexesSendComplex = (
  options?: MutationOptions<boolean, ErrorReason, ComplexChangesStateDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/send-complex",
    method: "POST",
    options,
  });

export const useComplexesDelete = (
  options?: MutationOptions<unknown, ErrorReason, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/delete",
    method: "DELETE",
    options,
  });

export const useComplexesArchitectureSendComplex = (
  options?: MutationOptions<unknown, ErrorReason, ComplexChangeStateDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/architecture-send-complex",
    method: "POST",
    options,
  });

export const useComplexesChangeVisible = (
  options?: MutationOptions<unknown, ErrorReason, ComplexChangeStateDto>
) =>
  useGeneralAPIMutation({
    url: "/complexes/change-visible",
    method: "POST",
    options,
  });
