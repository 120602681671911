import { returnArrayIfIsset } from "utils";
import {
  useReferenceGetAllResidentSchemaType,
  useReferenceGetAllNoResidentSchemaType,
} from "../endpoints/references";

/** Schema turlarini olish */
export const useSchemaTypeOptions = () => {
  const residentQuery = useReferenceGetAllResidentSchemaType();
  const noResidentQuery = useReferenceGetAllNoResidentSchemaType();

  const residentSchemaTypeOptions = returnArrayIfIsset(residentQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  const noResidentSchemaTypeOptions = returnArrayIfIsset(noResidentQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  return {
    residentSchemaTypeOptions,
    noResidentSchemaTypeOptions,
  };
};
