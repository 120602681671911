import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps } from "components/shared";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export interface ToBackProps extends Omit<ButtonProps, "icon"> {
  title?: string;
  to?: string;
}

export const ToBackButton: FC<ToBackProps> = ({ title, to, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button {...props} icon={<LeftOutlined />} onClick={() => to ?? navigate(-1)}>
      {title ?? t("Орқага")}
    </Button>
  );
};
