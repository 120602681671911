import { MutationOptions } from "hooks/custom";
import { ErrorDto } from "types";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { AxiosRequestConfig } from "axios";

export interface ChunkResponseDto {
  fileGuid: string;
  uploadDate: string;
  chunkNumber: number;
  totalChunks: number;
}

export interface ChunkReceivedDto {
  fileName: string;
  contentType: string;
  extension: string;
  chunkNumber: number;
  totalChunks: number;
  chunkData: any[];
  fileGuid?: string;
  uploadDate?: string;
}

export const useChunkFilesUpload = (
  options?: MutationOptions<ChunkResponseDto, ErrorDto, FormData>,
  config?: AxiosRequestConfig<ChunkResponseDto>
) =>
  useGeneralAPIMutation({
    url: "/chunk/files/upload",
    method: "POST",
    options,
    config,
  });
