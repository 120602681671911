import { ComponentProps } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import classes from "./PageMenu.module.scss";

export interface IPageMenu {
  href: string;
  label: string;
  isActive?: boolean;
}

interface IProps extends ComponentProps<"div"> {
  menus: IPageMenu[];
  hasGoBackBtn?: boolean;
}

const PageMenu = ({ hasGoBackBtn = false, menus = [], className, ...props }: IProps) => {
  return (
    <div {...props} className={cn(classes.main, className)}>
      <nav className={classes.list}>
        {menus.map(({ href, label, isActive }) => (
          <Link
            to={href}
            key={href}
            className={cn(classes.item, isActive && classes.active)}
          >
            {label}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default PageMenu;
