import { QueryOptions, useGeneralAPIQuery } from "hooks";
import {
  ErrorDto,
  ErrorReason,
  Pageable,
  ResponsePagingDTOFlatDashboardDto,
  ResponsePagingDTOFlatGovernmentDto,
} from "types";

export interface FlatsGetAllParams extends Pageable {
  complexId?: number;
  blockId?: number;
  flatNumber?: number;
}

export const useFlatsGetAll = (
  params?: FlatsGetAllParams,
  options?: QueryOptions<ResponsePagingDTOFlatDashboardDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/flats/get-all`,
    options,
    params,
  });

export const useFlatsGetAllByBlockId = (
  params?: FlatsGetAllParams,
  options?: QueryOptions<ResponsePagingDTOFlatDashboardDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/flats/get-all-by-block-id`,
    options,
    params,
  });

export interface FlatsGetGovernmentParams
  extends Pageable,
    Partial<{
      complexId: number;
      blockId: number;
      flatStateId: number;
      flatNumber: number;
    }> {}

export const useFlatsGetAllForGovernment = (
  params?: FlatsGetGovernmentParams,
  options?: QueryOptions<ResponsePagingDTOFlatGovernmentDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/flats/get-all-for-government",
    params,
    options,
  });

export const useFlatsGroupByState = (
  params?: { complexId?: number },
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/flats/group-by-state",
    params,
    options,
  });

export const useFlatsGroupByResident = (
  params?: { complexId?: number },
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/flats/group-by-resident",
    params,
    options,
  });

export const useFlatsGroupByResidentAdmin = (
  params?: { complexId?: number; stateId?: number },
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/flats/group-by-resident-admin",
    params,
    options,
  });

export const useFlatsGroupByNoResident = (
  params?: { complexId?: number },
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/flats/group-by-no-resident",
    params,
    options,
  });

export const useFlatsGroupByNoResidentAdmin = (
  params?: { complexId?: number; stateId?: number },
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/flats/group-by-no-resident-admin",
    params,
    options,
  });
