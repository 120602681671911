import { returnArrayIfIsset } from "utils";
import { useUsersBuilderRoles } from "../endpoints/users";

/** Rolelar ro'yhati builder uchun */
export const useUserBuilderRolesOptions = () => {
  const { data } = useUsersBuilderRoles();

  const userBuilderRolesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    userBuilderRolesOptions,
  };
};
