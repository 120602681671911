import { QueryOptions, useGeneralAPIQuery } from "hooks";
import { EntranceDetailDto, EntranceDto, ErrorReason, FloorDetailDto } from "types";

export const useEntrancesGetByBlock = (
  params: { id: number },
  options?: QueryOptions<EntranceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/entrances/get-by-block`,
    options,
    params,
  });

export const useEntrancesGetEntranceFlats = (
  params: { id: number; typeId?: number },
  options?: QueryOptions<EntranceDetailDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/entrances/get-entrance-flats`,
    options,
    params,
  });

export const useEntrancesGetFlatByFloor = (
  params: { entranceId: number; floor: number },
  options?: QueryOptions<FloorDetailDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/entrances/get-flat-by-floor`,
    options,
    params,
  });
