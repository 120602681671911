import {
  useReferenceGetAllAreaByDistrictId,
  useReferenceGetAllDistrictByRegionId,
  useReferenceGetAllRegion,
} from "../endpoints/references";
import { returnArrayIfIsset } from "utils";

/** Viloyat, Tuman va Korxonalarning ro'yxatni olish */
export const useAreaOptions = (
  regionId?: number | undefined,
  districtId?: number | undefined
) => {
  const regionQuery = useReferenceGetAllRegion();
  const districtQuery = useReferenceGetAllDistrictByRegionId(regionId!, {
    enabled: !!regionId,
  });
  const areaQuery = useReferenceGetAllAreaByDistrictId(districtId!, {
    enabled: !!districtId,
  });

  const regionOptions = returnArrayIfIsset(regionQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  const districtOptions = returnArrayIfIsset(districtQuery?.data?.data!).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  );

  const areaOptions = returnArrayIfIsset(areaQuery?.data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    regionOptions,
    districtOptions,
    areaOptions,
    isLoadingDistrict: districtQuery.isFetching,
    isLoadingArea: areaQuery.isFetching,
  };
};
