import { notification } from "antd";
import { useFilesDownload, useFilesUploads } from "hooks";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { blobToBase64, getFileNameFromHeaders } from "utils";
import { useChunkUpload } from "./useChunkUpload";

export const useFilesManager = () => {
  const { t } = useTranslation();
  const [isLoadingGuidsToFiles, setIsLoadingGuidsToFiles] = useState(false);
  const [isLoadingFilesToGuids, setIsLoadingFilesToGuids] = useState(false);
  const { chunkUploadFile } = useChunkUpload();

  const downloadMutation = useFilesDownload({});

  const uploadsMutation = useFilesUploads({
    onError({ response, message }) {
      notification.warning({
        message: t("Файлларни юклаб олишда хатолик юз берди"),
        description: response?.data?.message ?? message,
      });
    },
  });

  const guidsToFiles = useCallback(
    async (guids: string[]) => {
      if (!guids?.length) return [];
      setIsLoadingGuidsToFiles(true);

      const filePromises = guids.map((guid) =>
        downloadMutation.mutateAsync({ slug: guid }).then(async (resp) => ({
          ...resp,
          guid,
          thumbUrl: await blobToBase64(resp.data),
        }))
      );

      return await Promise.all(filePromises)
        .then((resp) =>
          resp.map(({ data, headers, guid, thumbUrl }) => {
            const file = new File([data], getFileNameFromHeaders(headers), {
              type: headers?.["content-type"],
            });

            return {
              guid,
              uid: guid,
              name: file?.name,
              status: "done",
              thumbUrl,
              originFileObj: file,
            };
          })
        )
        .catch(async ({ response, message }) => {
          const reason = await response?.data?.text();

          notification.warning({
            message: t("Файлларни юклаб олишда хатолик юз берди"),
            description: reason ?? message,
          });

          return [];
        })
        .finally(() => setIsLoadingGuidsToFiles(false));
    },
    [t]
  );

  const filesToGuids = useCallback(
    async (files: File[]) => {
      if (!files.length) return [];

      setIsLoadingFilesToGuids(true);

      const guids = [] as string[];

      for (const file of files) {
        await chunkUploadFile(file, (guid) => guids.push(guid!));
      }

      setIsLoadingFilesToGuids(false);

      return guids;
    },
    [uploadsMutation]
  );

  return {
    guidsToFiles,
    filesToGuids,
    isLoadingFilesToGuids: uploadsMutation.isLoading || isLoadingFilesToGuids,
    isLoadingGuidsToFiles: downloadMutation.isLoading || isLoadingGuidsToFiles,
  };
};
