import { returnArrayIfIsset } from "utils";
import { useBlocksGetAllShort } from "../endpoints/blocks";
import { useMemo } from "react";

export const useBlocksShortOptions = (
  params?: Partial<{
    companyId: number;
    complexId: number;
  }>
) => {
  const { data } = useBlocksGetAllShort(params);

  const blocksShortOptions = useMemo(
    () =>
      returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  );

  return {
    blocksShortOptions,
  };
};
