import { QueryOptions } from "hooks/custom";
import { ErrorDto, ReferenceDto } from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";

export const usePrReferenceGetAllRentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-rent-types`,
    options,
  });

export const usePrReferenceGetAllRelativeTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-relative-types`,
    options,
  });

export const usePrReferenceGetAllPopulationTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-population-types`,
    options,
  });

export const usePrReferenceGetAllNoLiveTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-no-live-types`,
    options,
  });

export const usePrReferenceGetAllEmploymentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-employment-types`,
    options,
  });

export const usePrReferenceGetAllCurrentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-current-types`,
    options,
  });

export const usePrReferenceGetAllBasisTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-basis-types`,
    options,
  });

export const usePrReferenceGetAllVillages = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-villages`,
    options,
  });

export const usePrReferenceGetAllAnimals = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-animals`,
    options,
  });

//  ==================================================
export const usePrReferenceMVillageTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-village-types`,
    options,
  });

export const usePrReferenceMRentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-rent-types`,
    options,
  });

export const usePrReferenceMRelativeTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-relative-types`,
    options,
  });

export const usePrReferenceMPopulationTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-population-types`,
    options,
  });

export const usePrReferenceMNoLiveTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-no-live-types`,
    options,
  });

export const usePrReferenceMEmploymentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-employment-types`,
    options,
  });

export const usePrReferenceMCurrentTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-current-types`,
    options,
  });

export const usePrReferenceMBasisTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-basis-types`,
    options,
  });

export const usePrReferenceMAnimalTypes = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/pr-reference/get-all-m-animal-types`,
    options,
  });
