import { Card, Col, Empty, Image, Modal, ModalProps, Row, Skeleton } from "antd";
import { FALLBACK_EMPTY_IMAGE } from "constants/other";
import { useFile, useFilesManager, useSchemasGet, useSchemasGetByFlat } from "hooks";
import { FC, Fragment, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { returnArrayIfIsset } from "utils";
import { useModal } from "../Modals";

export interface ModalFlatsBlockDetailsProps extends Omit<ModalProps, "onCancel"> {
  flatSchemaId: number;
}

export const ModalFlatsBlockDetails: FC<ModalFlatsBlockDetailsProps> = ({
  flatSchemaId,
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { files, setFiles, clear } = useFile();
  const { guidsToFiles, isLoadingGuidsToFiles } = useFilesManager();
  const [file] = files;

  const { data, isFetching, failureReason } = useSchemasGetByFlat(
    { flatId: flatSchemaId },
    { enabled: !!flatSchemaId }
  );

  const schemaData = useMemo(() => data?.data, [data]);

  useEffect(() => {
    clear();
    (async () => {
      const photoFiles = await guidsToFiles(schemaData?.guids ?? []);
      setFiles(photoFiles);
    })();
  }, [schemaData, setFiles]);

  const items = [
    { label: t("Майдони"), value: `${schemaData?.totalArea} ${t("кв.м")}` },
    { label: t("Хонадон тури"), value: schemaData?.nameUz },
    { label: t("Тоифаси"), value: schemaData?.spFlatTypeDto?.name },
    { label: t("Яшаш майдони"), value: `${schemaData?.livingArea} ${t("кв.м")}` },
    ...returnArrayIfIsset(schemaData?.rooms!).map(({ roomName, area }) => ({
      label: roomName,
      value: `${area} ${t("кв.м")}`,
    })),
  ];

  return (
    <Modal
      open
      width={660}
      footer={null}
      title={<h3 className="mb20 text-center">{t("Батафсил маълумот")}</h3>}
      onCancel={closeModal}
      className="flat-details-modal"
    >
      <Card className="h100 flex-both-center">
        {(() => {
          if (isFetching || isLoadingGuidsToFiles)
            return (
              <Row gutter={[20, 20]} wrap={false} align="middle">
                <Col md={14}>
                  <Skeleton
                    paragraph={{ rows: 9 }}
                    active
                    className="w100"
                    rootClassName="w100"
                  />
                </Col>
                <Skeleton active />
                <Col md={10}>
                  <Skeleton.Node
                    active
                    className="w100"
                    rootClassName="w100"
                    style={{ height: 200 }}
                  />
                </Col>
              </Row>
            );

          if (!schemaData) {
            return (
              <Empty
                description={
                  failureReason?.response?.data?.message ??
                  failureReason?.message ??
                  t("Хонадон танланмаган")
                }
              />
            );
          } else {
            return (
              <Row gutter={[25, 25]}>
                <Col xs={24} lg={14}>
                  {items.map(({ label, value }, idx) => (
                    <Fragment key={idx}>
                      <Row align="middle" gutter={[10, 10]}>
                        <Col md={14}>
                          <p className="size16 light">{label}:</p>
                        </Col>
                        <Col md={10}>
                          <p className="bold size17 dark">{value}</p>
                        </Col>
                      </Row>
                      {items.length !== idx + 1 && <div className="dashed my8" />}
                    </Fragment>
                  ))}
                </Col>
                <Col xs={24} lg={10}>
                  <Image.PreviewGroup
                    items={files.map((file) => URL.createObjectURL(file))}
                  >
                    <Image
                      src={file && URL.createObjectURL(file)}
                      fallback={FALLBACK_EMPTY_IMAGE}
                      height="100%"
                      className="object-cover w100"
                      rootClassName="w100"
                      alt="schema"
                    />
                  </Image.PreviewGroup>
                </Col>
              </Row>
            );
          }
        })()}
      </Card>
    </Modal>
  );
};
