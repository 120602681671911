import { useReferenceGetAllCurrency } from "../endpoints/references";

/** Valyutalar ro'yxatni olish olish */
export const useCurrencyOptions = () => {
  const { data } = useReferenceGetAllCurrency();

  const currencyOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    currencyOptions,
  };
};
