import { MutationOptions } from "hooks/custom";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ContractCreateDto, ErrorReason } from "types";

export const useContractsCreate = (
  options?: MutationOptions<unknown, ErrorReason, ContractCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/contracts/create",
    method: "POST",
    options,
  });

export const useContractsCancel = (
  options?: MutationOptions<unknown, ErrorReason, unknown>
) =>
  useGeneralAPIMutation({
    url: "/contracts/cancel",
    method: "POST",
    options,
  });

export const useContractsChangePaymentTerm = (
  options?: MutationOptions<
    unknown,
    ErrorReason,
    { contractId: number; paymentTerm: string }
  >
) =>
  useGeneralAPIMutation({
    url: "/contracts/change-payment-term",
    method: "POST",
    options,
  });
