import { notification } from "antd";
import { AVAILABLE_USERS_LIST } from "constants/ui";
import { useUser, useUsersMeMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { setUserInfo, setUserRole, useAppDispatch } from "store";
import { UserRoles } from "types";

export const useRegisterUser = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { logoutUser } = useUser();

  return useUsersMeMutation({
    onSuccess({ data }) {
      const role = data?.role as UserRoles;

      if (AVAILABLE_USERS_LIST.includes(role)) {
        dispatch(setUserRole(role));
        dispatch(setUserInfo(data));
      } else {
        throw new Error(t("Фойдаланувчи роли тўғри келмади"));
      }
    },
    onError({ response, message }) {
      logoutUser();
      notification.error({
        message: t("Фойдаланувчи маълумотларини олишда хатолик юз берди"),
        description: response?.data?.message ?? message,
      });
    },
  });
};
