import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Image, Modal, ModalProps, Row } from "antd";
import { useModal } from "../Modals";
import { LogoutOutlined, CheckOutlined } from "@ant-design/icons";
import {
  Controller,
  useFieldArray,
  useForm,
  Control,
  UseFormGetValues,
} from "react-hook-form";
import { ColumnsType, Icon, Input, Select, Table } from "components/shared";
import { addSpaceEvery3Char, formatNumber } from "utils";
import { SchemaCreateDto, SchemaFullDto } from "types";
import { UseFileCustomArg, UseFileResult, useRoomTypeOptions } from "hooks";
import { FALLBACK_EMPTY_IMAGE } from "constants/other";

interface FormValues {
  area: string;
  roomTypeId: number;
}

type TableRecordDto = FormValues & { id: string };

export interface AddCompositionProps extends Omit<ModalProps, "onCancel"> {
  control: Control<SchemaCreateDto, any>;
  planFileController: UseFileResult<any>;
  getValues: UseFormGetValues<SchemaCreateDto>;
  schemaState:
    | (Omit<SchemaFullDto, "guids"> & {
        files: UseFileCustomArg[];
      })
    | undefined;
  setRoomsCallback: (values: SchemaCreateDto["rooms"]) => void;
}

export const AddComposition: FC<AddCompositionProps> = ({
  control: parentControl,
  getValues,
  schemaState,
  planFileController: { files },
  setRoomsCallback,
  ...props
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { roomTypeOptions } = useRoomTypeOptions();
  const [file] = files;
  const { totalArea, nameUz } = getValues();

  const { control, handleSubmit, reset } = useForm<FormValues>({});

  const { remove, fields, prepend } = useFieldArray({
    name: "rooms",
    control: parentControl,
  });

  const columns: ColumnsType<TableRecordDto> = [
    {
      title: "№",
      render: (_, __, idx) => idx + 1,
      align: "center",
      width: 50,
    },
    {
      title: t("Номланиши"),
      dataIndex: "roomTypeId",
      render: (roomTypeId) => (
        <>{roomTypeOptions?.find(({ value }) => value === roomTypeId)?.label}</>
      ),
    },
    {
      title: t("Майдони"),
      dataIndex: "area",
      align: "center",
      render: (value: number) => addSpaceEvery3Char(value),
    },
    {
      title: t("Амаллар"),
      align: "center",
      width: 100,
      render: (_, __, idx) => (
        <div className="f-center">
          <Button
            type="link"
            danger
            icon={<Icon name="trash" width={20} height={20} />}
            onClick={() => remove(idx)}
          />
        </div>
      ),
    },
  ];

  const onPrependSubmit = handleSubmit((value) => {
    prepend({
      area: value?.area as any,
      roomTypeId: value?.roomTypeId,
    });
    reset({});
  });

  const onCloseModal = () => {
    setRoomsCallback(getValues("rooms"));
    closeModal();
  };

  return (
    <Modal
      open
      {...props}
      footer={null}
      maskClosable={false}
      onCancel={onCloseModal}
      title={
        <h2 className="text-center bold mb20">
          {nameUz && `${nameUz},`} {formatNumber(totalArea)} {t("кв.м")}
        </h2>
      }
      width={800}
    >
      <div className="p30">
        <Row gutter={[30, 30]} className="mb40">
          <Col xs={24} lg={10}>
            <Image.PreviewGroup items={files.map((file) => URL.createObjectURL(file))}>
              <Image
                src={file && URL.createObjectURL(file)}
                fallback={FALLBACK_EMPTY_IMAGE}
                height="100%"
                className="object-cover w100"
                rootClassName="w100"
                alt="schema"
              />
            </Image.PreviewGroup>
          </Col>
          <Col xs={24} lg={14}>
            <Row gutter={[20, 20]} className="mb20">
              <Col lg={10}>
                <Controller
                  name="roomTypeId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w100"
                      size="large"
                      placeholder={t("Номланиши")}
                      options={roomTypeOptions}
                    />
                  )}
                />
              </Col>
              <Col lg={10}>
                <Controller
                  name="area"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size="large"
                      type="number"
                      placeholder={t("Майдони")}
                    />
                  )}
                />
              </Col>
              <Col lg={3}>
                <Button size="large" icon={<LogoutOutlined />} onClick={onPrependSubmit} />
              </Col>
            </Row>

            <Table<TableRecordDto>
              rowKey={({ id }) => id}
              scroll={{ y: 200 }}
              bordered
              dataSource={fields as any[]}
              size="small"
              columns={columns}
              pagination={false}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Button
            size="large"
            type="primary"
            shape="round"
            className="px50"
            icon={<CheckOutlined />}
            onClick={onCloseModal}
          >
            {t("Сақлаш")}
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
