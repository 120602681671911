import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ESITokeDto, ErrorReason, JwtRequest, JwtResponse } from "types";

export const useAuthLogin = (
  options?: MutationOptions<JwtResponse, ErrorReason, JwtRequest>
) =>
  useGeneralAPIMutation({
    url: "/auth/login",
    method: "POST",
    options,
    config: {
      headers: {
        Authorization: null,
      },
    },
  });

export const useAuthClientSecrets = (
  options?: MutationOptions<{ clientId: string; state: string }, ErrorReason>
) =>
  useGeneralAPIMutation({
    url: "/auth/client-secrets",
    options,
    config: {
      headers: {
        Authorization: null,
      },
    },
  });

export const useAuthEsiToken = (
  options?: MutationOptions<unknown, ErrorReason, ESITokeDto>
) =>
  useGeneralAPIMutation({
    url: "/auth/esi-token",
    method: "POST",
    options,
    config: {
      headers: {
        Authorization: null,
      },
    },
  });
