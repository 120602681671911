import { returnArrayIfIsset } from "utils";
import { useComplexesGetAllForAddBlock } from "../endpoints/complexes";

/** Barcha Kompleks nomi va idsini olish yangi block qo'shish uchun */
export const useComplexForAddBlockOptions = () => {
  const { data } = useComplexesGetAllForAddBlock();

  const complexForAddBlockOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    complexForAddBlockOptions,
  };
};
