import { MutationOptions, useGeneralAPIMutation } from "hooks";
import { DeleteDto, ErrorReason, UserCreateDto, UserDto, UserUpdateDto } from "types";

export const useUsersMeMutation = (options?: MutationOptions<UserDto, ErrorReason>) =>
  useGeneralAPIMutation({
    url: `/users/me`,
    options,
  });

export const useUsersDelete = (options?: MutationOptions<DeleteDto, ErrorReason>) =>
  useGeneralAPIMutation({
    url: `/users/delete`,
    method: "DELETE",
    options,
  });

export const useUsersCreate = (options?: MutationOptions<UserCreateDto, ErrorReason>) =>
  useGeneralAPIMutation({
    url: `/users/create`,
    method: "POST",
    options,
  });

export const useUsersUpdate = (options?: MutationOptions<UserUpdateDto, ErrorReason>) =>
  useGeneralAPIMutation({
    url: `/users/update`,
    method: "PUT",
    options,
  });
