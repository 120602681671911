export const enum FLAT_STATE_COLORS {
  BOOKED = "#A8A8A8",
  NOT_BOOKED = "#12BC56",
  PAID = "#0B2A99",
}

export const enum PLACE_STATUS_COLORS {
  RESIDENCE = "#E9573F",
  NON_RESIDENCE = "#0062AE",
}

export const enum PROCESS_STATUS_COLORS {
  NEW = "#8CC152",
  SENT_ARCHITECTURE = "#364BA8",
  ARCHITECTURE_APPROVED = "#10B981",
  ARCHITECTURE_REJECTED = "#E9573F",
  AUTHORITY_CONFIRMED = "#5FA710",
  AUTHORITY_REJECTED = "#C07367",
}
