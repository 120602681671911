import { MutationOptions, useGeneralAPIMutation } from "hooks";
import { CompanyCreateDto, DeleteDto, ErrorDto } from "types";

export const useCompaniesCreate = (
  options?: MutationOptions<unknown, ErrorDto, CompanyCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/companies/create",
    method: "POST",
    options,
  });

export const useCompaniesDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/companies/delete",
    method: "DELETE",
    options,
  });

// export const useCompaniesUpdate = (
//   options?: MutationOptions<unknown, ErrorDto, CompanyUpdateDto>
// ) =>
//   useGeneralAPIMutation({
//     url: "/companies/update",
//     method: "PUT",
//     options,
// });
