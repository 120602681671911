import { QueryOptions } from "hooks/custom";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";
import { ErrorDto, ExternalDto } from "types";

export const useAuthGetCompanyInfo = (
  params: { tin: string },
  options?: QueryOptions<ExternalDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/auth/get-company-info",
    params,
    options,
  });
