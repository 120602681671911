import { FC } from "react";
import { ColumnWidthOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { Button } from "components/shared";
import { useLocalStorage } from "hooks";
import { useTranslation } from "react-i18next";

export const FullViewController: FC = () => {
  const { t } = useTranslation();
  const [isFull, setIsFull] = useLocalStorage("isFull", true);

  return (
    <div className="flex-justify-end">
      {!isFull ? (
        <Button
          size="middle"
          type="link"
          icon={<ColumnWidthOutlined className="size30" />}
          className="flex-both-center"
          onClick={() => setIsFull(true)}
          title={t("Катта ўлчамда кўриш")}
        />
      ) : (
        <Button
          size="middle"
          type="link"
          icon={
            <VerticalAlignMiddleOutlined
              className="size30"
              style={{ transform: "rotate(90deg)" }}
            />
          }
          className="flex-both-center"
          onClick={() => setIsFull(false)}
          title={t("Кичкина ўлчамда кўриш")}
        />
      )}
    </div>
  );
};

