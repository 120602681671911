import { MutationOptions } from "hooks/custom";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ErrorReason, OrderCreateDto, OrderUpdateDto } from "types";

export const useOrdersCreate = (
  options: MutationOptions<unknown, ErrorReason, OrderCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/orders/create",
    method: "POST",
    options,
  });

export const useOrdersUpdate = (
  options: MutationOptions<unknown, ErrorReason, OrderUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/orders/update",
    method: "PUT",
    options,
  });
