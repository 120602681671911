import { FC, Key } from "react";
import "./ApartmentView.scss";
import { Button, ButtonProps, Truncate } from "components/shared";
import cn from "classnames";
import { ApartmentFlat, ApartmentViewProps } from "./ApartmentView";

export interface FlatBoxButtonProps {
  id?: Key;
  type?: number;
  record?: any;
  num?: number;
  onClick?: (value?: ApartmentFlat & Record<string, any>) => void;
  colors?: ApartmentViewProps["colors"];
  buttonProps?: Omit<ButtonProps, "type" | "onClick" | "id">;
}

export const FlatBoxButton: FC<FlatBoxButtonProps> = ({
  id,
  record,
  type,
  num,
  onClick,
  colors,
  buttonProps,
}) => {
  const currentColor = colors?.find((i) => i?.type === type);
  const value = String(num ?? "");

  return (
    <Button
      {...buttonProps}
      shape="circle"
      size="small"
      className={cn("apartment-view__flat-box", buttonProps?.className)}
      style={{ background: currentColor?.color }}
      onClick={() => onClick?.(record)}
    >
      {value.length > 3 ? `${value.slice(0, 3)}.` : value}
    </Button>
  );
};
