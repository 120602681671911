import { MutateVariables, MutationOptions } from "hooks/custom";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import {
  DeleteDto,
  ErrorDto,
  GspInfoDto,
  PrPopulationCreateDto,
  PrPopulationUpdateDto,
} from "types";

export const usePrPopulationCreate = (
  options?: MutationOptions<unknown, ErrorDto, PrPopulationCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-population/create",
    method: "POST",
    options,
  });

export const usePrPopulationUpdate = (
  options?: MutationOptions<unknown, ErrorDto, PrPopulationUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-population/update",
    method: "PUT",
    options,
  });

export const usePrPopulationDelete = (
  options?: MutationOptions<unknown, ErrorDto, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/pr-population/delete",
    method: "DELETE",
    options,
  });

export const usePrPopulationSyncGcpInfo = (
  options?: MutationOptions<
    { result: string; data: GspInfoDto[] },
    ErrorDto,
    MutateVariables<unknown, unknown, { pinfl: string; birthDay: string }>
  >
) =>
  useGeneralAPIMutation({
    url: "/pr-population/sync-gcp-info",
    method: "GET",
    options,
  });
