import { Tag } from "antd";
import { PROCESS_STATUS_COLORS } from "constants/colors";
import { PROCESS_STATUS } from "constants/ui";
import { PropsWithChildren, ReactNode } from "react";

export interface Props {
  value: number;
}

export const ProcessStatus = ({ value, children }: PropsWithChildren<Props>) => {
  const statuses: Record<PROCESS_STATUS, ReactNode> = {
    [PROCESS_STATUS.NEW]: <Tag color={PROCESS_STATUS_COLORS.NEW}>{children}</Tag>,
    [PROCESS_STATUS.SENT_ARCHITECTURE]: (
      <Tag color={PROCESS_STATUS_COLORS.SENT_ARCHITECTURE}>{children}</Tag>
    ),
    [PROCESS_STATUS.ARCHITECTURE_APPROVED]: (
      <Tag color={PROCESS_STATUS_COLORS.ARCHITECTURE_APPROVED}>{children}</Tag>
    ),
    [PROCESS_STATUS.ARCHITECTURE_REJECTED]: (
      <Tag color={PROCESS_STATUS_COLORS.ARCHITECTURE_REJECTED}>{children}</Tag>
    ),
    [PROCESS_STATUS.AUTHORITY_CONFIRMED]: (
      <Tag color={PROCESS_STATUS_COLORS.AUTHORITY_CONFIRMED}>{children}</Tag>
    ),
    [PROCESS_STATUS.AUTHORITY_REJECTED]: (
      <Tag color={PROCESS_STATUS_COLORS.AUTHORITY_REJECTED}>{children}</Tag>
    ),
  };

  return <>{statuses?.[value as PROCESS_STATUS] ?? "-"}</>;
};
