import { MutationOptions, useDownloadFile } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ErrorReason } from "types";
import { AxiosRequestConfig } from "axios";
import { useAPIInstances } from "services";

/** Yangi fayl yuklash */
export const useFilesUpload = (
  options?: MutationOptions<string, ErrorReason, FormData>,
  config?: AxiosRequestConfig<string>
) =>
  useGeneralAPIMutation({
    url: "/files/upload",
    method: "POST",
    config: {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        "Content-Type": "multipart/form-data",
      },
    },
    options,
  });

/** Guid bo'yicha faylni yuklash  */
export const useFilesDownload = (options?: MutationOptions<File, Blob>) =>
  useGeneralAPIMutation({
    url: "/files/download",
    method: "GET",
    options,
    config: {
      responseType: "blob",
    },
  });

/** Yangi fayllarni yuklash */
export const useFilesUploads = (
  options?: MutationOptions<string[], ErrorReason, FormData>,
  config?: AxiosRequestConfig<string[]>
) =>
  useGeneralAPIMutation({
    url: "/files/uploads",
    method: "POST",
    config: {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        "Content-Type": "multipart/form-data",
      },
    },
    options,
  });

/** Guid Bo'yicha faylni avto yuklab olish */
export const useFilesAutoDownload = (options?: MutationOptions<File, Blob>) => {
  const { generalRequest } = useAPIInstances();

  return useDownloadFile({
    url: "/files/download",
    method: "GET",
    instance: generalRequest,
    options,
    config: {
      responseType: "blob",
    },
  });
};
