import { QueryOptions } from "hooks/custom";
import { ErrorDto, GspInfoDto, PrPopulationShortDto } from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";
import { PR_POPULATION_TYPE } from "constants/ui";

export const usePrPopulationGroupByPopulationType = (
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-population/group-by-population-type",
    options,
  });

export const usePrPopulationGroupByEmploymentType = (
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-population/group-by-employment-type",
    options,
  });

export const usePrPopulationGroupByCommonData = (
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-population/group-by-common-data",
    options,
  });

export const usePrPopulationGroupByCurrentType = (
  options?: QueryOptions<Record<string, any>[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-population/group-by-current-type",
    options,
  });

export const usePrPopulationGetShortBy = (
  params: { apartmentId: number; populationTypeId: PR_POPULATION_TYPE },
  options?: QueryOptions<PrPopulationShortDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-population/get-short-by",
    params,
    options: {
      ...options,
      cacheTime: 0,
    },
  });
