import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllAnimals } from "../endpoints/pr-reference";

export const useAnimalsOptions = () => {
  const { data } = usePrReferenceGetAllAnimals();

  const animalsOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    animalsOptions,
  };
};
