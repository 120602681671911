import { FLAT_TYPE } from "constants/ui";
import { useSchemasListByBlock } from "../endpoints/schemas";
import { returnArrayIfIsset } from "utils";
import { useTranslation } from "react-i18next";

export const useSchemasListOptions = (blockId: number) => {
  const { t } = useTranslation();

  const schemasResidenceQuery = useSchemasListByBlock(
    { blockId: blockId, typeId: FLAT_TYPE.RESIDENCE },
    { enabled: !!blockId }
  );
  const schemasNonResidenceQuery = useSchemasListByBlock(
    { blockId: blockId, typeId: FLAT_TYPE.NON_RESIDENCE },
    { enabled: !!blockId }
  );

  const residenceFlatSchemaOptions = returnArrayIfIsset(
    schemasResidenceQuery.data?.data!
  ).map(({ totalArea, id, name }) => ({
    label: `${name} (${totalArea} ${t("кв")}.)`,
    value: id,
  }));

  const nonResidenceFlatSchemaOptions = returnArrayIfIsset(
    schemasNonResidenceQuery.data?.data!
  ).map(({ totalArea, id, name }) => ({
    label: `${name} (${totalArea} ${t("кв")}.)`,
    value: id,
  }));

  return { residenceFlatSchemaOptions, nonResidenceFlatSchemaOptions };
};
