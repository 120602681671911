import { useReferenceGetAllComplexClass } from "../endpoints/references";

/** Kompleks klasslar ro'yxatni olish */
export const useComplexClassOptions = () => {
  const { data } = useReferenceGetAllComplexClass();

  const complexClassOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    complexClassOptions,
  };
};
