import {
  ForwardRefExoticComponent,
  Key,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import "./ApartmentView.scss";
import { Icon } from "components/shared";
import { Empty, Row, Spin } from "antd";
import cn from "classnames";
import { FlatBoxButton } from "./FlatBoxButton";

export interface ApartmentFlat {
  id?: Key;
  num: number;
  type?: number;
}

export interface ApartmentData {
  floor: number;
  flats: ApartmentFlat[];
}

export interface ApartmentViewProps {
  title?: ReactNode;
  entranceNum?: ReactNode;
  data?: ApartmentData[];
  loading?: boolean;
  onClick?: (value: any) => void;
  renderFlats?: (record: ApartmentFlat & Record<string, any>, idx: number) => ReactNode;
  renderFloorNum?: (currentFloor: number, flats: ApartmentData["flats"]) => ReactNode;
  colors?: {
    type: number;
    color: string;
  }[];
}

const ApartmentViewComponent = forwardRef<HTMLElement, ApartmentViewProps>(
  (
    {
      title,
      entranceNum = 1,
      data = [],
      loading = false,
      onClick,
      renderFlats,
      renderFloorNum,
      colors,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const isEmpty = !data.length;

    return (
      <article ref={ref} className="apartment-view__wrapper">
        <Spin spinning={loading}>
          <div className="apartment-view__head">
            {entranceNum && (
              <div className="apartment-view__entrance-num">{entranceNum}</div>
            )}
            <p className="apartment-view__entrance-name">{title ?? t("ПОДЪЕЗД")}</p>
          </div>
          <div
            className={cn("apartment-view__body", isEmpty && "apartment-view__body-empty")}
          >
            <div className="apartment-view__inner">
              {(() => {
                if (isEmpty)
                  return (
                    <Empty image={<Icon name="empty" />} imageStyle={{ width: 180 }} />
                  );

                return data.map(({ flats, floor }) => {
                  return (
                    <Row
                      key={floor}
                      className={cn(
                        "apartment-view__row-floor",
                        floor < 0 && "apartment-view__row-floor-basement"
                      )}
                      wrap={false}
                      align="middle"
                    >
                      <div className="apartment-view__num-floor" title={t("Қават")}>
                        {renderFloorNum ? renderFloorNum(floor, flats) : floor}
                      </div>

                      {flats &&
                        flats.map(({ num, type, id, ...rest }, index) => {
                          const record = { floor, num, id, type, colors, ...rest };

                          if (renderFlats) {
                            return renderFlats(record, index);
                          }

                          return (
                            <FlatBoxButton key={id} {...{ onClick, record, ...record }} />
                          );
                        })}
                    </Row>
                  );
                });
              })()}
            </div>
          </div>
        </Spin>
      </article>
    );
  }
);

export const ApartmentView = ApartmentViewComponent as CompoundedComponent;

interface CompoundedComponent
  extends ForwardRefExoticComponent<ApartmentViewProps & RefAttributes<HTMLElement>> {
  FlatButton: typeof FlatBoxButton;
}

ApartmentView.FlatButton = FlatBoxButton;
