import { FC, PropsWithChildren, useState, createContext, useContext } from "react";
import {
  AddComposition,
  AddCompositionProps,
  Contract,
  ContractChangePaymentTerm,
  ContractChangePaymentTermProps,
  ContractProps,
  ModalFlatsBlockDetails,
  ModalFlatsBlockDetailsProps,
  Receipt,
  ReceiptProps,
} from "./components";

export interface ModalProps {
  "add-composition": AddCompositionProps;
  contract: ContractProps;
  receipt: ReceiptProps;
  "flats-block-details": ModalFlatsBlockDetailsProps;
  "contract-change-payment-term": ContractChangePaymentTermProps;
}

export type ModalName = keyof ModalProps;

export type ModalType<T extends ModalName> = {
  name: T;
  props: ModalProps[T];
};

export interface ModalsContextType {
  openModal: <T extends ModalName>(name: T, props: ModalProps[T]) => void;
  closeModal: VoidFunction;
  currentModal: ModalType<ModalName> | null;
}

export const ModalsContext = createContext<ModalsContextType | null>(null);

const modalComponentMap: Record<ModalName, FC<any>> = {
  "add-composition": AddComposition,
  contract: Contract,
  receipt: Receipt,
  "flats-block-details": ModalFlatsBlockDetails,
  "contract-change-payment-term": ContractChangePaymentTerm,
  // Add other modal components here
};

export const ModalsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<ModalsContextType["currentModal"]>(null);

  const openModal = <T extends ModalName>(name: T, props: ModalProps[T]) => {
    setCurrentModal({ name, props });
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  const renderCurrentModal = ({ name, props }: ModalType<ModalName>) => {
    const ModalComponent = modalComponentMap[name];
    return ModalComponent ? <ModalComponent {...props} /> : null;
  };

  return (
    <ModalsContext.Provider value={{ openModal, closeModal, currentModal }}>
      {children}
      {currentModal && renderCurrentModal(currentModal)}
    </ModalsContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalsContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
