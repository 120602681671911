import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PaginationStoreState = Record<string, State>;

interface State {
  page: number;
  pageSize: number;
}

interface Payload {
  key: string;
  value: number;
}

const initialState = {} as PaginationStoreState;

const beforeInitialize = (state: PaginationStoreState, payload: Payload) => {
  if (!state[payload.key]) {
    state[payload.key] = {} as State;
  }
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<Payload>) => {
      beforeInitialize(state, payload);

      state[payload.key].page = payload.value;
    },
    setPageSize: (state, { payload }: PayloadAction<Payload>) => {
      beforeInitialize(state, payload);

      state[payload.key].pageSize = payload.value;
    },
  },
});

export const { setPage, setPageSize } = paginationSlice.actions;
