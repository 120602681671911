import { FC } from "react";
import classes from "./StatusDots.module.scss";
import { Space } from "antd";
import { SpaceSize } from "antd/es/space";

export interface StatusDotsProps {
  size?: SpaceSize;
  items?: {
    title: string;
    color: string;
  }[];
}

export const StatusDots: FC<StatusDotsProps> = ({ items = [], size = "large" }) => {
  return (
    <Space size={size}>
      {items?.map(({ color, title }) => (
        <Space key={title}>
          <span className={classes.dot} style={{ background: color }} />
          <p>{title}</p>
        </Space>
      ))}
    </Space>
  );
};
