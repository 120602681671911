import { returnArrayIfIsset } from "utils";
import { useReferenceGetAllFlatState } from "../endpoints/references";

/** Xonadon statuslari ro'yxatni olish */
export const useFlatStateOptions = () => {
  const { data } = useReferenceGetAllFlatState();

  const flatStateOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    flatStateOptions,
  };
};
