import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllNoLiveTypes } from "../endpoints/pr-reference";

export const useNoLiveTypesOptions = () => {
  const { data } = usePrReferenceGetAllNoLiveTypes();

  const noLiveTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    noLiveTypesOptions,
  };
};
