import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import {
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Modal,
  Row,
  Space,
  notification,
} from "antd";
import { Button, FormItem, Icon, Input } from "components/shared";
import { ApartmentFlat, Upload, useModal } from "components/ui";
import { DATE_FORMAT } from "constants/general";
import {
  useContractsGetByFlatId,
  useFile,
  useFilesManager,
  useReceiptsCreate,
} from "hooks";
import i18n from "services/i18n";
import { formatDateToSend, formatNumber, isDate, parseToDate, requiredField } from "utils";
import { z } from "zod";
import { queryClient } from "services";

const schema = z.object({
  contractNumber: requiredField(),
  contractDate: z.coerce
    .date({
      errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }),
    })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
  receipt: requiredField(),
  receiptDate: z.coerce
    .date({ errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }) })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
});

type FormValues = z.infer<typeof schema>;

export interface ReceiptProps extends ApartmentFlat, Record<string, any> {
  contractId: number;
}

export const Receipt: FC<ReceiptProps> = ({
  num,
  id,
  floor,
  type,
  flatStateName,
  flatTypeName,
  price,
  contractId,
}) => {
  const { t } = useTranslation();
  const fileCtrl = useFile();
  const { filesToGuids, isLoadingFilesToGuids } = useFilesManager();
  const { closeModal } = useModal();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const byFlatIdQuery = useContractsGetByFlatId(
    { flatId: Number(id) },
    {
      enabled: !!id,
      onError({ message, response }) {
        notification.warning({
          message: t("Маълумотларни олишда хатолик юз берди"),
          description: response?.data?.message ?? message,
        });
      },
    }
  );

  const dataByFlatId = useMemo(() => byFlatIdQuery.data?.data!, [byFlatIdQuery.data]);

  useEffect(() => {
    setValue("contractDate", parseToDate(dataByFlatId?.contractDate)!);
    setValue("contractNumber", dataByFlatId?.contractNumber!);
  }, [dataByFlatId]);

  const receiptsCreateMutation = useReceiptsCreate({
    onSuccess() {
      queryClient.invalidateQueries(["/blocks/get-block-flats"]);
      queryClient.invalidateQueries(["/flats/get-all"]);
      queryClient.invalidateQueries(["/contracts/get-by-flat-id"]);
      closeModal();
      notification.success({ message: t("Квитанция муваффақиятли сақланди") });
    },
    onError({ response, message }) {
      notification.error({
        message: t("Квитанцияни сақлашда хатолик юз берди"),
        description: response?.data?.message ?? message,
      });
    },
  });

  const descriptionItems: DescriptionsProps["items"] = [
    {
      label: t("Қават"),
      children: floor,
    },
    {
      label: t("Ҳолати"),
      children: flatStateName,
    },
    {
      label: t("Жой тоифаси"),
      children: flatTypeName,
    },
    {
      label: t("Хонадон номери"),
      children: num,
    },
    {
      label: t("Нархи"),
      children: formatNumber(price),
    },
  ];

  const onSubmit = handleSubmit(async ({ contractDate, receiptDate, ...values }) => {
    const [fileGuid] = [
      ...fileCtrl.fileGuids,
      ...(await filesToGuids(fileCtrl.filesWithoutGuid)),
    ];
    receiptsCreateMutation.mutate({
      payload: {
        ...values,
        flatId: Number(id),
        contractId,
        guid: fileGuid,
        contractDate: formatDateToSend(contractDate) as string,
        receiptDate: formatDateToSend(receiptDate) as string,
      },
    });
  });

  return (
    <Modal
      open
      footer={null}
      width={800}
      title={<h2 className="text-center mb30">{t("Кветанция")}</h2>}
      onCancel={closeModal}
      maskClosable={false}
    >
      <form onSubmit={onSubmit}>
        <Row gutter={[40, 40]} className="mb20">
          <Col
            lg={9}
            style={{ borderRight: "1px solid #122067" }}
            className="flex-align-center"
          >
            <Descriptions
              items={descriptionItems}
              column={1}
              contentStyle={{
                fontWeight: 700,
              }}
            />
          </Col>
          <Col lg={15}>
            <p className="mb8">{t("Шартнома")}</p>
            <Space
              className="mb10 gap-15 w100"
              classNames={{ item: "w100" }}
              align="baseline"
            >
              <Controller
                name="contractNumber"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    placeholder={t("Номери")}
                    disabled
                    error={errors?.contractNumber?.message}
                  />
                )}
              />
              <Controller
                name="contractDate"
                control={control}
                render={({ field }) => (
                  <FormItem error={errors?.contractDate?.message as any} className="w100">
                    <DatePicker
                      {...(field as any)}
                      size="large"
                      className="w100"
                      placeholder={t("Санаси")}
                      allowClear={false}
                      format={DATE_FORMAT}
                      disabled
                      suffixIcon={<Icon name="calendar-dates" />}
                      status={errors?.contractDate?.message && "error"}
                    />
                  </FormItem>
                )}
              />
            </Space>
            <p className="mb8">{t("Кветанция")}</p>
            <Space
              className="mb10 gap-15 w100"
              classNames={{ item: "w100" }}
              align="baseline"
            >
              <Controller
                name="receipt"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    placeholder={t("Номери")}
                    error={errors?.receipt?.message}
                  />
                )}
              />
              <Controller
                name="receiptDate"
                control={control}
                render={({ field }) => (
                  <FormItem error={errors?.receiptDate?.message as any} className="w100">
                    <DatePicker
                      {...(field as any)}
                      size="large"
                      className="w100"
                      placeholder={t("Санаси")}
                      allowClear={false}
                      format={DATE_FORMAT}
                      suffixIcon={<Icon name="calendar-dates" />}
                      status={errors?.receiptDate?.message && "error"}
                    />
                  </FormItem>
                )}
              />
            </Space>
          </Col>
        </Row>

        <Upload
          fileController={fileCtrl}
          multiple={false}
          buttonProps={{ disabled: fileCtrl.fileList.length >= 1 }}
        />

        <Row justify="center" className="mt40">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="px40"
            htmlType="submit"
            loading={receiptsCreateMutation.isLoading || isLoadingFilesToGuids}
          >
            {t("Сақлаш")}
          </Button>
        </Row>
      </form>
    </Modal>
  );
};
