import { isArray } from "utils";
import { useReferenceGetAllSpDetails } from "../endpoints/references";

/** Qulayliklar ro'yxatni olish olish  */
export const useDetailsOptions = () => {
  const { data } = useReferenceGetAllSpDetails();

  const detailsOptions = isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    detailsOptions,
  };
};
