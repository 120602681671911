import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllRentTypes } from "../endpoints/pr-reference";

export const useRentTypesOptions = () => {
  const { data } = usePrReferenceGetAllRentTypes();

  const rentTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    rentTypesOptions,
  };
};
