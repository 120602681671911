import { DownloadOutlined } from "@ant-design/icons";
import { Button, ColumnsType, Table } from "components/shared";
import { useComplexesGetDocuments, useFilesAutoDownload } from "hooks";
import { useTranslation } from "react-i18next";
import { AppFileDto } from "types";
import { catchDefaultError, truncateMid } from "utils";

export function DocumentsList({ id }: { id: number }) {
  const { t } = useTranslation();
  const { download } = useFilesAutoDownload({
    onSettled(_, error) {
      if (error) {
        catchDefaultError(error as any);
      }
    },
  });

  const getDocsQuery = useComplexesGetDocuments(id);

  const columns: ColumnsType<AppFileDto> = [
    {
      width: 50,
      title: "№",
      dataIndex: "id",
      align: "center",
      render: (_: any, __: any, i: number) => <>{i + 1}</>,
    },
    {
      title: t("Номи"),
      dataIndex: "name",
      align: "left",
      width: 200,
      render(value, { fileGuid }) {
        return (
          <Button
            type="link"
            className="color-primary"
            icon={<DownloadOutlined />}
            onClick={() => download({ slug: fileGuid })}
          >
            {truncateMid(value, 20)}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={({ fileGuid }) => fileGuid!}
      loading={getDocsQuery.isLoading}
      size="small"
      columns={columns}
      dataSource={getDocsQuery.data?.data}
      pagination={false}
    />
  );
}
