import { UserRoles } from "types";

export const enum FLAT_TYPE {
  RESIDENCE = 1,
  NON_RESIDENCE = 2,
}

export const enum PROCESS_STATUS {
  NEW = 1,
  SENT_ARCHITECTURE = 2,
  ARCHITECTURE_APPROVED = 3,
  ARCHITECTURE_REJECTED = 4,
  AUTHORITY_CONFIRMED = 5,
  AUTHORITY_REJECTED = 6,
}

export const enum COMPLEX_PROCESS_STATUS {
  PLANNED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

export const enum FLAT_STATE {
  BOOKED = 2,
  NOT_BOOKED = 1,
  PAID = 3,
}

export const enum ARCHITECT_STATE {
  NEW = 2,
  APPROVED = 3,
  REJECTED = 4,
}

export const CAN_ACTION_COMPLEX_IDS = [
  PROCESS_STATUS.NEW,
  PROCESS_STATUS.ARCHITECTURE_REJECTED,
];

export const AVAILABLE_USERS_LIST: UserRoles[] = [
  "ADMIN",
  "ARCHITECTURE",
  "BUILDER",
  "GOVERNMENT",
  "XATLOV",
];

export const BUILDER_ROLE_ID = 5;

export const enum COMPANY_SHORT_TYPE {
  BUILDER = "3",
  OTHER = "2",
}

export enum PR_POPULATION_TYPE {
  OWN_APARTMENT = 1, // Уй эгаси
  PERMANENTLY_LISTED = 2, // Доимий рўйҳатда
  UNIT_16_YEAR = 3, // 16 ёшгача
  IN_RENT = 4, // Ижарада
  NOT_REGISTERED = 5, // Рўйҳатдан ўтмаган
}

export const POPULATION_TYPES = [
  "IN_RENT",
  "NOT_REGISTERED",
  "OWN_APARTMENT",
  "PERMANENTLY_LISTED",
  "UNIT_16_YEAR",
] as const;

export const HASH_POPULATION_TYPES: Record<number, (typeof POPULATION_TYPES)[number]> = {
  1: "OWN_APARTMENT",
  2: "PERMANENTLY_LISTED",
  3: "UNIT_16_YEAR",
  4: "IN_RENT",
  5: "NOT_REGISTERED",
};

export const HASH_POPULATION_TYPES_BY_NAME: Record<
  (typeof POPULATION_TYPES)[number],
  number
> = {
  OWN_APARTMENT: 1,
  PERMANENTLY_LISTED: 2,
  UNIT_16_YEAR: 3,
  IN_RENT: 4,
  NOT_REGISTERED: 5,
};
