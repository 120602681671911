import classes from "./PageTitle.module.scss";

interface IProps {
	title: string;
}

const PageTitle = ({ title }: IProps) => {
	return <div className={classes.title}>{title}</div>;
};

export default PageTitle;
