import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { DeleteDto, ErrorReason, SchemaCreateDto, SchemaUpdateDto } from "types";

export const useSchemasCreate = (
  options?: MutationOptions<unknown, ErrorReason, SchemaCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/schemas/create",
    method: "POST",
    options,
  });

export const useSchemasDelete = (
  options?: MutationOptions<unknown, ErrorReason, DeleteDto>
) =>
  useGeneralAPIMutation({
    url: "/schemas/delete",
    method: "DELETE",
    options,
  });

export const useSchemasUpdate = (
  options?: MutationOptions<unknown, ErrorReason, SchemaUpdateDto>
) =>
  useGeneralAPIMutation({
    url: "/schemas/update",
    method: "POST",
    options,
  });
