import { CadasterDto, ErrorDto, Pageable, PageableResp, PrApartmentDto } from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";
import { QueryOptions } from "hooks/custom";

export const usePrApartmentGetAll = (
  params?: Pageable & Record<string, any>,
  options?: QueryOptions<PageableResp<PrApartmentDto>, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-apartment/get-all",
    params,
    options,
  });

export const usePrApartmentHistory = (
  params: { apartmentId: number },
  options?: QueryOptions<{ pinfl: string; date: string }[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-apartment/history",
    params,
    options,
  });

export const usePrApartmentSyncData = (
  params: { pinfl: string },
  options?: QueryOptions<string, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-apartment/sync-data",
    params,
    options,
  });

export const usePrApartmentSyncCadasterInfo = (
  params: { cadaster: string },
  options?: QueryOptions<CadasterDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/pr-apartment/sync-cadaster-info",
    params,
    options,
  });
