import { QueryOptions, useGeneralAPIQuery } from "hooks";
import {
  ErrorDto,
  ReferenceDto,
  SpAreaDto,
  SpCompanyDto,
  SpComplexClassDto,
  SpComplexMaterialTypeDto,
  SpComplexStateDto,
  SpDetailsDto,
  SpDistrictDto,
  SpFileTypeDto,
  SpFlatStateDto,
  SpFlatTypeDto,
  SpObjectTypeDto,
  SpRegionDto,
  SpRoomTypeDto,
} from "types";

// Qulayliklar ro'yxatni olish
export const useReferenceModifyGetAllSpDetails = (
  options?: QueryOptions<SpDetailsDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-sp-details`,
    options,
  });

// Xonadon turlari ro'yxatni olish
export const useReferenceModifyGetAllRoomType = (
  options?: QueryOptions<SpRoomTypeDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-room-type`,
    options,
  });

// Viloyatlar ro'yxatni olish
export const useReferenceModifyGetAllRegion = (
  options?: QueryOptions<SpRegionDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-region`,
    options,
  });

// Object turlari ro'yxatni olish
export const useReferenceModifyGetAllObjectType = (
  options?: QueryOptions<SpObjectTypeDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-object-type`,
    options,
  });

// Xonadon turlari ro'yxatni olish
export const useReferenceModifyGetAllFlatType = (
  options?: QueryOptions<SpFlatTypeDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-flat-type`,
    options,
  });

// Xonadon statuslari ro'yxatni olish
export const useReferenceModifyGetAllFlatState = (
  options?: QueryOptions<SpFlatStateDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-flat-state`,
    options,
  });

// Fayl turlarining ro'yxatni olish
export const useReferenceModifyGetAllFileType = (
  options?: QueryOptions<SpFileTypeDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-file-type`,
    options,
  });

// Viloyat kodi bo'yicha Tumanlar ro'yxatni olish
export const useReferenceModifyGetAllDistrictByRegionId = (
  regionId: number,
  options?: QueryOptions<SpDistrictDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-district-by-region-id/${regionId}`,
    options,
  });

// Kompleks Statuslar ro'yxatni olish
export const useReferenceModifyGetAllComplexState = (
  options?: QueryOptions<SpComplexStateDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-complex-state`,
    options,
  });

// Kompleks klass materiallar ro'yxatni olish
export const useReferenceModifyGetAllComplexMaterialType = (
  options?: QueryOptions<SpComplexMaterialTypeDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-complex-material-type`,
    options,
  });

// Kompleks klasslar ro'yxatni olish
export const useReferenceModifyGetAllComplexClass = (
  options?: QueryOptions<SpComplexClassDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-complex-class`,
    options,
  });

// Korxona turlarni ro'yxatni olish
export const useReferenceModifyGetAllCompanyType = (
  options?: QueryOptions<SpCompanyDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-company-type`,
    options,
  });

// Korxona turlarni ro'yxatni olish
export const useReferenceModifyGetAllAreaByDistrictId = (
  districtId: number,
  options?: QueryOptions<SpAreaDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-area-by-district-id/${districtId}`,
    options,
  });

export const useReferenceModifyGetAllComplexPrice = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-complex-price`,
    options,
  });

export const useReferenceModifyGetAllCurrency = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-currency`,
    options,
  });

export const useReferenceModifyGetAllProcessState = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-process-state`,
    options,
  });

export const useReferenceModifyResidentSchemaType = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-resident-schema-type`,
    options,
  });

export const useReferenceModifyNoResidentSchemaType = (
  options?: QueryOptions<ReferenceDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/reference-modify/get-all-no-resident-schema-type`,
    options,
  });
