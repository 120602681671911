import { QueryOptions } from "hooks/custom";
import {
  AppFileDto,
  BlockDetailDto,
  BlockDto,
  EntranceDetailDto,
  ErrorReason,
  FlatDetailDto,
  PageableParam,
  PageableResp,
  ResponsePagingDTOBlockFlatsDto,
  ShortDto,
} from "types";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";

export const useBlocksGetAll = (
  params?: PageableParam<{
    number?: string | undefined;
    companyId?: number;
    complexId?: number;
  }>,
  options?: QueryOptions<PageableResp<BlockDto>, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/blocks/get-all",
    options,
    params,
  });

export const useBlocksGetBlockFlats = (
  params: { id: number; typeId?: number },
  options?: QueryOptions<EntranceDetailDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/blocks/get-block-flats`,
    params,
    options,
  });

export const useBlocksGetDetails = (
  id: number,
  options?: QueryOptions<BlockDetailDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/blocks/get-details/${id}`,
    options,
  });

export const useBlocksGetPhotos = (
  id: number,
  options?: QueryOptions<AppFileDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/blocks/get-photos/${id}`,
    options,
  });

export const useBlocksGet = (id: number, options?: QueryOptions<BlockDto, ErrorReason>) =>
  useGeneralAPIQuery({
    url: `/blocks/get/${id}`,
    options,
  });

export const useBlocksGetAllShort = (
  params?: Partial<{ companyId: number; complexId: number }>,
  options?: QueryOptions<ShortDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/blocks/get-all-short",
    params,
    options,
  });

export const useBlocksGetAllForGovernment = (
  params?: PageableParam<{ complexId?: number }>,
  options?: QueryOptions<ResponsePagingDTOBlockFlatsDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/blocks/get-all-for-government",
    params,
    options,
  });
