import { QueryOptions } from "hooks/custom";
import { useGeneralAPIQuery } from "../useGeneralAPIQuery";
import { ErrorReason, SchemaDetailDto, SchemaDto, SchemaFullDto } from "types";

export const useSchemasListByBlock = (
  params: { blockId: number; typeId?: number },
  options?: QueryOptions<SchemaDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/schemas/list-by-block",
    params,
    options,
  });

export const useSchemasDetails = (
  params: { schemaId: number },
  options?: QueryOptions<SchemaDetailDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/schemas/details",
    params,
    options,
  });

export const useSchemasGet = (
  id: number,
  options?: QueryOptions<SchemaFullDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/schemas/get/${id}`,
    options,
  });

export const useSchemasGetByFlat = (
  params: { flatId: number },
  options?: QueryOptions<SchemaFullDto, ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/schemas/get-by-flat",
    params,
    options,
  });
