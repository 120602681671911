import { useReferenceGetAllComplexPrice } from "../endpoints/references";

/** Complex price turlarini ro'yxatni olish olish */
export const useComplexPriceOptions = () => {
  const { data } = useReferenceGetAllComplexPrice();

  const complexPriceOptions = Array.isArray(data?.data)
    ? data?.data?.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return {
    complexPriceOptions,
  };
};
