import { QueryOptions, useGeneralAPIQuery } from "hooks";
import { ContractDto, ErrorDto } from "types";

export const useContractsGetByFlatId = (
  params: { flatId: number },
  options?: QueryOptions<ContractDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/contracts/get-by-flat-id`,
    options,
    params,
  });

export const useContractsGetAllByFlatId = (
  params: { flatId: number },
  options?: QueryOptions<ContractDto[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/contracts/get-all-by-flat-id`,
    options,
    params,
  });

export const useGetActionByContractId = (
  params: { contractId: number },
  options?: QueryOptions<
    {
      actionDate: string;
      actionDescription: string;
      actionTypeName: string;
    }[],
    ErrorDto
  >
) =>
  useGeneralAPIQuery({
    url: `/contracts/get-action-by-contract-id`,
    options,
    params,
  });
