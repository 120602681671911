import { FC, Fragment, useContext, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Skeleton } from "antd";
import { useComplexesGet } from "hooks";
import { DetailInfoContext } from "../DetailInfoContext";
import { Truncate } from "components/shared";
import { formatDateToDisplay } from "utils";

export const About: FC = () => {
  const { t } = useTranslation();
  const { setClientHeight } = useContext(DetailInfoContext);
  const contentHeightRef = useRef<HTMLDivElement>(null);
  const { complexId } = useParams<{ complexId: string }>();

  const complexQuery = useComplexesGet(Number(complexId), { enabled: !!complexId });
  const dataComplex = complexQuery?.data?.data;

  useLayoutEffect(() => {
    const clientRect = contentHeightRef.current?.getBoundingClientRect();

    setClientHeight(clientRect?.height!);
  }, [setClientHeight, complexQuery.data]);

  const items = [
    {
      label: t("Манзил"),
      value: <Truncate>{dataComplex?.address}</Truncate>,
    },
    {
      label: t("Раҳбари"),
      value: "-",
    },
    {
      label: t("Уй-жой синфи"),
      value: dataComplex?.className,
    },
    {
      label: t("Ҳолати"),
      value: dataComplex?.stateName,
    },
    {
      label: t("Уй тури"),
      value: dataComplex?.objectTypeName,
    },
    {
      label: t("Тугатиш"),
      value: formatDateToDisplay(dataComplex?.endDate) as string,
    },
    {
      label: t("Материал"),
      value: dataComplex?.materialTypeName,
    },
    {
      label: t("Хонадонлар"),
      value: dataComplex?.flatCount,
    },
  ];

  return (
    <Card className="px16" ref={contentHeightRef}>
      {items.map(({ label, value }, idx) => (
        <Fragment key={idx}>
          <Row align="middle">
            <Col md={10}>
              <p className="size16 light">{label}:</p>
            </Col>
            <Col md={14}>
              {complexQuery.isLoading ? (
                <Skeleton.Input active size="small" className="w100" rootClassName="w100" />
              ) : (
                <p className="bold size17 dark">{value}</p>
              )}
            </Col>
          </Row>
          {items.length !== idx + 1 && <div className="dashed my8" />}
        </Fragment>
      ))}
    </Card>
  );
};
