import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllCurrentTypes } from "../endpoints/pr-reference";

export const useCurrentTypesOptions = () => {
  const { data } = usePrReferenceGetAllCurrentTypes();

  const currentTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    currentTypesOptions,
  };
};
