import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import classes from "./PageTitleWithBack.module.scss";
import { Icon } from "components/shared";

interface IProps {
  title: string;
  hasGoBackBtn?: boolean;
}

const PageTitleWithBack = ({ hasGoBackBtn = false, title = "" }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      {hasGoBackBtn && (
        <Button
          onClick={() => navigate(-1)}
          className={classes.goBack}
          icon={<Icon name="chevron-left" />}
          size="large"
        >
          {t("ортга")}
        </Button>
      )}

      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default PageTitleWithBack;
