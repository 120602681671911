import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllBasisTypes } from "../endpoints/pr-reference";

export const useBasisTypesOptions = () => {
  const { data } = usePrReferenceGetAllBasisTypes();

  const basisTypesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    basisTypesOptions,
  };
};
