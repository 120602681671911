import {
  Upload as AntdUpload,
  UploadProps as AntUploadProps,
  Card,
  notification,
  Skeleton,
  Space,
} from "antd";
import { acceptFiles } from "constants/other";
import { FileValue, UseFileResult } from "hooks";
import { ComponentProps, FC } from "react";
import classes from "./Upload.module.scss";
import saveAs from "file-saver";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "components/shared";
import { FileTwoTone, PaperClipOutlined } from "@ant-design/icons";
import cn from "classnames";

export interface UploadProps extends AntUploadProps {
  fileController: UseFileResult;
  isLoading?: boolean;
  showButton?: boolean;
  buttonProps?: ComponentProps<typeof Button>;
}

export const Upload: FC<UploadProps> = ({
  fileController: { fileList, remove, set },
  isLoading = false,
  showButton = true,
  buttonProps,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const uploadProps: AntUploadProps = {
    listType: "picture",
    className: cn(classes.uploadWrapper, className),
    beforeUpload: () => false,
    multiple: true,
    accept: acceptFiles,
    iconRender: (file, extension) => {
      return <FileTwoTone />;
    },
    showUploadList: { showDownloadIcon: true, showPreviewIcon: true },
    onDownload: (file) => {
      console.log("download file:", file);
      try {
        saveAs(get(file, "originFileObj")! as Blob, file?.name);
      } catch (error: any) {
        notification.warning({
          message: t("Файлни юклашда хатолик юз берди"),
          description: error?.message,
        });
      }
    },
    ...props,
  };

  return (
    <Card>
      {isLoading ? (
        <Space size="large">
          <Skeleton.Input style={{ height: 55 }} active />
          <Skeleton.Input style={{ height: 55 }} active />
          <Skeleton.Input style={{ height: 55 }} active />
        </Space>
      ) : (
        <AntdUpload
          {...uploadProps}
          fileList={fileList}
          onRemove={(file) => {
            const index = fileList.indexOf(file)!;
            remove(index);
          }}
          onChange={({ file }) => {
            if (file.status !== "removed") {
              set(file as FileValue);
            }
          }}
        >
          {showButton && (
            <Button
              icon={<PaperClipOutlined />}
              type="dashed"
              disabled={props.disabled}
              {...buttonProps}
            >
              {t("Файл бириктириш")}
            </Button>
          )}
        </AntdUpload>
      )}
    </Card>
  );
};
