import { useEffect, useMemo } from "react";
import { useComplexesGetPhotos, useFile, useFilesManager } from "hooks";
import { returnArrayIfIsset } from "utils";
import { FALLBACK_EMPTY_IMAGE } from "constants/other";
import { Image, Spin } from "antd";

interface Props {
  id: number;
  onCancel: VoidFunction;
}

export function PhotoVisibleList({ id, onCancel }: Props) {
  const { setFiles, files, clear } = useFile();
  const { guidsToFiles, isLoadingGuidsToFiles } = useFilesManager();

  const photosQuery = useComplexesGetPhotos(id);
  const dataPhotos = useMemo(
    () => returnArrayIfIsset(photosQuery.data?.data!).map(({ fileGuid }) => fileGuid!),
    [photosQuery.data]
  );

  const isLoading = photosQuery.isLoading || isLoadingGuidsToFiles;

  useEffect(() => {
    if (!dataPhotos?.length) return;

    (async () => {
      setFiles(await guidsToFiles(dataPhotos));
    })();

    return () => clear();
  }, [dataPhotos, guidsToFiles, setFiles, clear]);

  return isLoading ? (
    <Spin fullscreen />
  ) : (
    <Image.PreviewGroup
      items={files.map((file) => URL.createObjectURL(file))}
      fallback={FALLBACK_EMPTY_IMAGE}
      preview={{
        visible: true,
        onVisibleChange: onCancel,
      }}
    />
  );
}
