import { QueryOptions, useGeneralAPIQuery } from "hooks";
import {
  ErrorReason,
  ReferenceDto,
  SpAreaDto,
  SpCompanyDto,
  SpComplexClassDto,
  SpComplexMaterialTypeDto,
  SpComplexStateDto,
  SpDetailsDto,
  SpDistrictDto,
  SpFileTypeDto,
  SpFlatStateDto,
  SpFlatTypeDto,
  SpObjectTypeDto,
  SpRegionDto,
  SpRoomTypeDto,
} from "types";

/** @description Qulayliklar ro'yxatni olish */
export const useReferenceGetAllSpDetails = (
  options?: QueryOptions<SpDetailsDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-sp-details`,
    options,
  });

/** @description Xonadon turlari ro'yxatni olish */
export const useReferenceGetAllRoomType = (
  options?: QueryOptions<SpRoomTypeDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-room-type`,
    options,
  });

/** @description Viloyatlar ro'yxatni olish */
export const useReferenceGetAllRegion = (
  options?: QueryOptions<SpRegionDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-region`,
    options,
  });

/** @description Object turlari ro'yxatni olish */
export const useReferenceGetAllObjectType = (
  options?: QueryOptions<SpObjectTypeDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-object-type`,
    options,
  });

/** @description Xonadon turlari ro'yxatni olish */
export const useReferenceGetAllFlatType = (
  options?: QueryOptions<SpFlatTypeDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-flat-type`,
    options,
  });

/** @description Xonadon statuslari ro'yxatni olish */
export const useReferenceGetAllFlatState = (
  options?: QueryOptions<SpFlatStateDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-flat-state`,
    options,
  });

/** @description Fayl turlarining ro'yxatni olish */
export const useReferenceGetAllFileType = (
  options?: QueryOptions<SpFileTypeDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-file-type`,
    options,
  });

/** @description Viloyat kodi bo'yicha Tumanlar ro'yxatni olish */
export const useReferenceGetAllDistrictByRegionId = (
  regionId: number,
  options?: QueryOptions<SpDistrictDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-district-by-region-id/${regionId}`,
    options,
  });

/** @description Kompleks Statuslar ro'yxatni olish */
export const useReferenceGetAllComplexState = (
  options?: QueryOptions<SpComplexStateDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-complex-state`,
    options,
  });

/** @description Kompleks klass materiallar ro'yxatni olish */
export const useReferenceGetAllComplexMaterialType = (
  options?: QueryOptions<SpComplexMaterialTypeDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-complex-material-type`,
    options,
  });

/** @description Kompleks klasslar ro'yxatni olish */
export const useReferenceGetAllComplexClass = (
  options?: QueryOptions<SpComplexClassDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-complex-class`,
    options,
  });

/** @description Korxona turlarni ro'yxatni olish */
export const useReferenceGetAllCompanyType = (
  options?: QueryOptions<SpCompanyDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-company-type`,
    options,
  });

/** @description Korxona turlarni ro'yxatni olish */
export const useReferenceGetAllAreaByDistrictId = (
  districtId: number,
  options?: QueryOptions<SpAreaDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-area-by-district-id/${districtId}`,
    options,
  });

export const useReferenceGetAllComplexPrice = (
  options?: QueryOptions<ReferenceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-complex-price`,
    options,
  });

export const useReferenceGetAllCurrency = (
  options?: QueryOptions<ReferenceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-currency`,
    options,
  });

export const useReferenceGetAllProcessState = (
  options?: QueryOptions<ReferenceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: `/reference/get-all-process-state`,
    options,
  });

export const useReferenceGetAllResidentSchemaType = (
  options?: QueryOptions<ReferenceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/reference/get-all-resident-schema-type",
    options,
  });

export const useReferenceGetAllNoResidentSchemaType = (
  options?: QueryOptions<ReferenceDto[], ErrorReason>
) =>
  useGeneralAPIQuery({
    url: "/reference/get-all-no-resident-schema-type",
    options,
  });
