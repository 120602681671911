import { FC } from "react";
import classes from "./DetailInfo.module.scss";
import { Col, Row } from "antd";
import { Slider } from "./components/Slider";
import { About } from "./components/About";
import { DetailInfoContextProvider } from "./DetailInfoContext";

export const DetailInfo: FC = () => {
  return (
    <section className={classes.wrapper}>
      <DetailInfoContextProvider>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={14}>
            <Slider />
          </Col>
          <Col xs={24} md={10}>
            <About />
          </Col>
        </Row>
      </DetailInfoContextProvider>
    </section>
  );
};
