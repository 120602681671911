import { ApexOptions } from "apexcharts";
import { globalAntdConfig } from "config";

const colors = [
  "#3E4DCD",
  "#C07367",
  "#FFC107",
  "#E9573F",
  "#8cc152",
  "#d5b9a7",
  "#b3c3ee",
  "#6b8b48",
  "#091b44",
];

export const apexOptions = ({ labels }: { labels: ApexOptions["labels"] }): ApexOptions => {
  return {
    chart: { id: "donut_graph" },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: { size: "65%", background: "transparent", labels: { show: false } },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      style: {
        fontFamily: globalAntdConfig.token?.fontFamily,
        colors: ["white"],
        fontSize: "10px",
      },
      dropShadow: { enabled: false },
    },
    labels,
    tooltip: {
      shared: true,
      intersect: false,
    },
    stroke: { show: false },
    colors,
    fill: {
      colors,
      opacity: 1,
      type: "solid",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
      },
      pattern: {
        style: "verticalLines",
        width: 6,
        height: 6,
        strokeWidth: 20,
      },
    },
    legend: {
      position: "left",
      horizontalAlign: "left",
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      itemMargin: {
        vertical: 2,
      },
    },
  };
};
