import { AxiosRequestConfig } from "axios";
import { QueryOptions, useGeneralAPIQuery } from "hooks";
import {
  ErrorDto,
  Pageable,
  ReferenceDto,
  ResponsePagingDTOUserDto,
  UserDto,
  UserRoles,
} from "types";

export const useUsersMe = (
  options?: QueryOptions<UserDto, ErrorDto>,
  config?: AxiosRequestConfig<UserDto>
) =>
  useGeneralAPIQuery({
    url: `/users/me`,
    options,
    config,
  });

export const useUsersStaffStatisticCount = (options?: QueryOptions<any, ErrorDto>) =>
  useGeneralAPIQuery({
    url: "/users/staff-statistic-count",
    options,
  });

export const useUsersArchitectFio = (options?: QueryOptions<any, ErrorDto>) =>
  useGeneralAPIQuery({
    url: "/users/architect-fio",
    options,
  });

export const useUsersRoles = (
  options?: QueryOptions<{ id: number; name: UserRoles }[], ErrorDto>
) =>
  useGeneralAPIQuery({
    url: "/users/roles",
    options,
  });

export const useUsersGetAll = (
  params?: { role?: string; pinfl?: string; companyTin?: string } & Pageable,
  options?: QueryOptions<ResponsePagingDTOUserDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/users/get-all`,
    params,
    options,
  });

export const useUsersGetAllClient = (
  params?: { pinfl?: string } & Pageable,
  options?: QueryOptions<ResponsePagingDTOUserDto, ErrorDto>
) =>
  useGeneralAPIQuery({
    url: `/users/get-all-client`,
    params,
    options,
  });

export const useUsersBuilderRoles = (options?: QueryOptions<ReferenceDto[], ErrorDto>) =>
  useGeneralAPIQuery({
    url: `/users/builder-roles`,
    options,
  });
