import { zodResolver } from "@hookform/resolvers/zod";
import {
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Modal,
  Row,
  Space,
  Spin,
  notification,
} from "antd";
import { Button, FormItem, Icon, Input } from "components/shared";
import { ApartmentFlat, Upload, useModal } from "components/ui";
import { DATE_FORMAT } from "constants/general";
import { useContractsChangePaymentTerm, useFile, useFilesManager } from "hooks";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { queryClient } from "services";
import i18n from "services/i18n";
import { ContractDto } from "types";
import { formatDateToSend, formatNumber, isDate, parseToDate, requiredField } from "utils";
import { z } from "zod";

const schema = z.object({
  contractNumber: requiredField(),
  contractDate: z.coerce
    .date({
      errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }),
    })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
  pinfl: requiredField(),
  fio: requiredField(),
  paymentTerm: z.coerce
    .date({
      errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }),
    })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
});

type FormValues = z.infer<typeof schema>;

export interface ContractChangePaymentTermProps extends ApartmentFlat, Record<string, any> {
  dataByFlatId: ContractDto;
  byFlatIdQueryIsLoading: boolean;
}

export const ContractChangePaymentTerm: FC<ContractChangePaymentTermProps> = ({
  num,
  floor,
  flatStateName,
  flatTypeName,
  price,
  contractId,
  dataByFlatId,
  byFlatIdQueryIsLoading,
}) => {
  const { t } = useTranslation();
  const fileCtrl = useFile();
  const { guidsToFiles, isLoadingGuidsToFiles } = useFilesManager();
  const { closeModal } = useModal();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({
      fio: dataByFlatId?.fio,
      pinfl: dataByFlatId?.pinfl,
      contractNumber: dataByFlatId?.contractNumber,
      contractDate: parseToDate(dataByFlatId?.contractDate),
      paymentTerm: parseToDate(dataByFlatId?.paymentTerm),
    });

    (async () => {
      if (!dataByFlatId?.fileGuid) return;

      const files = await guidsToFiles([dataByFlatId?.fileGuid]);
      fileCtrl.setFiles(files);
    })();
  }, [dataByFlatId, reset, guidsToFiles]);

  const changePaymentTermMutation = useContractsChangePaymentTerm({
    onSuccess() {
      queryClient.invalidateQueries(["/blocks/get-block-flats"]);
      queryClient.invalidateQueries(["/contracts/get-by-flat-id"]);
      queryClient.invalidateQueries(["/flats/get-all"]);
      closeModal();
      notification.success({
        message: t("Муваффақиятли шартнома тўлов муддати ўзгартирилди"),
      });
    },
    onError({ response, message }) {
      notification.error({
        message: t("Шартнома тўлов муддатини ўзгартиришда хатолик юз берди"),
        description: response?.data?.message ?? message,
      });
    },
  });

  const descriptionItems: DescriptionsProps["items"] = [
    {
      label: t("Қават"),
      children: floor,
    },
    {
      label: t("Ҳолати"),
      children: flatStateName,
    },
    {
      label: t("Жой тоифаси"),
      children: flatTypeName,
    },
    {
      label: t("Хонадон номери"),
      children: num,
    },
    {
      label: t("Нархи"),
      children: formatNumber(price),
    },
  ];

  const onSubmit = handleSubmit(async ({ paymentTerm }) => {
    changePaymentTermMutation.mutate({
      payload: {
        contractId,
        paymentTerm: formatDateToSend(paymentTerm) as string,
      },
    });
  });

  return (
    <Modal
      open
      footer={null}
      width={800}
      title={
        <h2 className="text-center mb30">{t("Шартнома тўлов муддатини ўзгартириш")}</h2>
      }
      onCancel={closeModal}
      maskClosable={false}
    >
      <form onSubmit={onSubmit}>
        <Spin spinning={byFlatIdQueryIsLoading}>
          <Row gutter={[40, 40]} className="mb20">
            <Col
              lg={9}
              style={{ borderRight: "1px solid #122067" }}
              className="flex-align-center"
            >
              <Descriptions
                items={descriptionItems}
                column={1}
                contentStyle={{
                  fontWeight: 700,
                }}
              />
            </Col>
            <Col lg={15}>
              <p className="mb8">{t("Шартнома")}</p>
              <Space
                className="mb10 gap-15 w100"
                classNames={{ item: "w100" }}
                align="baseline"
              >
                <Controller
                  name="contractNumber"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size="large"
                      placeholder={t("Номери")}
                      error={errors?.contractNumber?.message}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="contractDate"
                  control={control}
                  render={({ field }) => (
                    <FormItem error={errors?.contractDate?.message as any} className="w100">
                      <DatePicker
                        {...(field as any)}
                        size="large"
                        className="w100"
                        placeholder={t("Санаси")}
                        allowClear={false}
                        format={DATE_FORMAT}
                        suffixIcon={<Icon name="calendar-dates" />}
                        status={errors?.contractDate?.message && "error"}
                        disabled
                      />
                    </FormItem>
                  )}
                />
              </Space>
              <p className="mb8">{t("Сотиб олувчи")}</p>
              <Row gutter={[10, 10]} className="mb20">
                <Col md={24}>
                  <Controller
                    name="pinfl"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        placeholder={t("ПИНФЛ")}
                        type="number"
                        error={errors?.pinfl?.message}
                        disabled
                      />
                    )}
                  />
                </Col>
                <Col md={24}>
                  <Controller
                    name="fio"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        placeholder={t("Ф.И.Ш.")}
                        error={errors?.fio?.message}
                        disabled
                      />
                    )}
                  />
                </Col>
              </Row>
              <p className="mb8">{t("Тўлов қилиш санаси")}</p>
              <Row>
                <Col md={24}>
                  <Controller
                    name="paymentTerm"
                    control={control}
                    render={({ field }) => (
                      <FormItem
                        error={errors?.paymentTerm?.message as any}
                        className="w100"
                      >
                        <DatePicker
                          {...(field as any)}
                          size="large"
                          className="w100"
                          placeholder={t("Санаси")}
                          allowClear={false}
                          format={DATE_FORMAT}
                          suffixIcon={<Icon name="calendar-dates" />}
                          status={errors?.paymentTerm?.message && "error"}
                        />
                      </FormItem>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>

        <Upload
          fileController={fileCtrl}
          multiple={false}
          isLoading={byFlatIdQueryIsLoading || isLoadingGuidsToFiles}
          buttonProps={{ disabled: fileCtrl.fileList.length >= 1 }}
          showButton={false}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: false,
          }}
        />

        <Row justify="center" className="mt40">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="px40"
            htmlType="submit"
            loading={changePaymentTermMutation.isLoading}
          >
            {t("Сақлаш")}
          </Button>
        </Row>
      </form>
    </Modal>
  );
};
