import { returnArrayIfIsset } from "utils";
import { usePrReferenceGetAllVillages } from "../endpoints/pr-reference";

/** Mahalla turlari */
export const useVillagesOptions = () => {
  const { data } = usePrReferenceGetAllVillages();

  const villagesOptions = returnArrayIfIsset(data?.data!).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    villagesOptions,
  };
};
