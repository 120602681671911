import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ErrorReason, FlatUpdateDtos, FlatsCreateDto, FloorCopyDto } from "types";

export const useFlatsCreate = (
  options: MutationOptions<unknown, ErrorReason, FlatsCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/flats/create",
    method: "POST",
    options,
  });

export const useFlatsFloorCopy = (
  options: MutationOptions<unknown, ErrorReason, FloorCopyDto>
) =>
  useGeneralAPIMutation({
    url: "/flats/floor-copy",
    method: "PUT",
    options,
  });

export const useFlatsUpdate = (
  options: MutationOptions<unknown, ErrorReason, FlatUpdateDtos>
) =>
  useGeneralAPIMutation({
    url: "/flats/update",
    method: "PUT",
    options,
  });

export const useFlatsChangePrice = (
  options: MutationOptions<unknown, ErrorReason, { id: number; price: number }>
) =>
  useGeneralAPIMutation({
    url: "/flats/change-price",
    method: "PUT",
    options,
  });
