import { zodResolver } from "@hookform/resolvers/zod";
import {
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Modal,
  Row,
  Space,
  notification,
} from "antd";
import { Button, FormItem, Icon, Input } from "components/shared";
import { ApartmentFlat, Upload, useModal } from "components/ui";
import { DATE_FORMAT } from "constants/general";
import { useContractsCreate, useFile, useFilesManager } from "hooks";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { queryClient } from "services";
import i18n from "services/i18n";
import { formatDateToSend, formatNumber, isDate, requiredField } from "utils";
import { z } from "zod";

const schema = z.object({
  contractNumber: requiredField(),
  contractDate: z.coerce
    .date({
      errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }),
    })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
  pinfl: requiredField(),
  fio: requiredField(),
  price: requiredField(),
  paymentTerm: z.coerce
    .date({
      errorMap: () => ({ message: i18n.t("Киритиш мажбурий") }),
    })
    .refine((date) => isDate(date), i18n.t("Хато сана")),
});

type FormValues = z.infer<typeof schema>;

export interface ContractProps extends ApartmentFlat, Record<string, any> {
  contractId: number;
}

export const Contract: FC<ContractProps> = ({
  num,
  id,
  floor,
  type,
  flatStateName,
  flatTypeName,
  price,
  contractId,
}) => {
  const { t } = useTranslation();
  const fileCtrl = useFile();
  const { filesToGuids, isLoadingFilesToGuids } = useFilesManager();
  const { closeModal } = useModal();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const contractsCreateMutation = useContractsCreate({
    onSuccess() {
      queryClient.invalidateQueries(["/blocks/get-block-flats"]);
      queryClient.invalidateQueries(["/flats/get-all"]);
      queryClient.invalidateQueries(["/contracts/get-by-flat-id"]);
      closeModal();
      notification.success({ message: t("Шартнома муваффақиятли сақланди") });
    },
    onError({ response, message }) {
      notification.error({
        message: t("Шартномани сақлашда хатолик юз берди"),
        description: response?.data?.message ?? message,
      });
    },
  });

  const descriptionItems: DescriptionsProps["items"] = [
    {
      label: t("Қават"),
      children: floor,
    },
    {
      label: t("Ҳолати"),
      children: flatStateName,
    },
    {
      label: t("Жой тоифаси"),
      children: flatTypeName,
    },
    {
      label: t("Хонадон номери"),
      children: num,
    },
    {
      label: t("Нархи"),
      children: formatNumber(price),
    },
  ];

  const onSubmit = handleSubmit(async ({ contractDate, paymentTerm, price, ...values }) => {
    if (!fileCtrl.files?.length) return;

    const [fileGuid] = [
      ...fileCtrl.fileGuids,
      ...(await filesToGuids(fileCtrl.filesWithoutGuid)),
    ];

    contractsCreateMutation.mutate({
      payload: {
        ...values,
        contractId,
        flatId: Number(id),
        price: Number(price),
        guid: fileGuid,
        contractDate: formatDateToSend(contractDate) as string,
        paymentTerm: formatDateToSend(paymentTerm) as string,
      },
    });
  });

  return (
    <Modal
      open
      footer={null}
      width={800}
      title={<h2 className="text-center mb30">{t("Шартнома")}</h2>}
      onCancel={closeModal}
      maskClosable={false}
    >
      <form onSubmit={onSubmit}>
        <Row gutter={[40, 40]} className="mb20">
          <Col
            lg={9}
            style={{ borderRight: "1px solid #122067" }}
            className="flex-align-center"
          >
            <Descriptions
              items={descriptionItems}
              column={1}
              contentStyle={{
                fontWeight: 700,
              }}
            />
          </Col>
          <Col lg={15}>
            <p className="mb8">{t("Шартнома")}</p>
            <Space
              className="mb10 gap-15 w100"
              classNames={{ item: "w100" }}
              align="baseline"
            >
              <Controller
                name="contractNumber"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    placeholder={t("Номери")}
                    error={errors?.contractNumber?.message}
                  />
                )}
              />
              <Controller
                name="contractDate"
                control={control}
                render={({ field }) => (
                  <FormItem error={errors?.contractDate?.message as any} className="w100">
                    <DatePicker
                      {...(field as any)}
                      size="large"
                      className="w100"
                      placeholder={t("Санаси")}
                      allowClear={false}
                      format={DATE_FORMAT}
                      suffixIcon={<Icon name="calendar-dates" />}
                      status={errors?.contractDate?.message && "error"}
                    />
                  </FormItem>
                )}
              />
            </Space>
            <p className="mb8">{t("Сотиб олувчи")}</p>
            <Row gutter={[10, 10]} className="mb20">
              <Col md={24}>
                <Controller
                  name="pinfl"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size="large"
                      placeholder={t("ПИНФЛ")}
                      type="number"
                      error={errors?.pinfl?.message}
                    />
                  )}
                />
              </Col>
              <Col md={24}>
                <Controller
                  name="fio"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size="large"
                      placeholder={t("Ф.И.Ш.")}
                      error={errors?.fio?.message}
                    />
                  )}
                />
              </Col>
              <Col md={24}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      size="large"
                      placeholder={t("Шартнома суммаси")}
                      suffix={`(${t("сўм")})`}
                      error={errors?.price?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <p className="mb8">{t("Тўлов қилиш санаси")}</p>
            <Row>
              <Col md={24}>
                <Controller
                  name="paymentTerm"
                  control={control}
                  render={({ field }) => (
                    <FormItem error={errors?.paymentTerm?.message as any} className="w100">
                      <DatePicker
                        {...(field as any)}
                        size="large"
                        className="w100"
                        placeholder={t("Санаси")}
                        allowClear={false}
                        format={DATE_FORMAT}
                        suffixIcon={<Icon name="calendar-dates" />}
                        status={errors?.paymentTerm?.message && "error"}
                      />
                    </FormItem>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <FormItem
          error={isSubmitted && !fileCtrl.files?.length && t("Шартнома файлни бириктиринг")}
        >
          <Upload
            fileController={fileCtrl}
            multiple={false}
            buttonProps={{ disabled: fileCtrl.fileList.length >= 1 }}
          />
        </FormItem>

        <Row justify="center" className="mt40">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="px40"
            htmlType="submit"
            loading={contractsCreateMutation.isLoading || isLoadingFilesToGuids}
          >
            {t("Сақлаш")}
          </Button>
        </Row>
      </form>
    </Modal>
  );
};
