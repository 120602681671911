import { MutationOptions } from "hooks";
import { useGeneralAPIMutation } from "../useGeneralAPIMutation";
import { ErrorReason, ReceiptCreateDto } from "types";

export const useReceiptsCreate = (
  options?: MutationOptions<unknown, ErrorReason, ReceiptCreateDto>
) =>
  useGeneralAPIMutation({
    url: "/receipts/create",
    method: "POST",
    options,
  });
